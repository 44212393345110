import { Customer } from 'types/customer';
import {
  getCustomerApiRequest,
} from '../apiRequests';
import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  getCustomerActions,
} from './actionTypes';

export const getCustomerThunk = defThunkRequest<{id: string}, Customer>({
  actionTypes: getCustomerActions,
  thunkSteps: [
    ({ data }) => getCustomerApiRequest(data.id),
  ],
});
