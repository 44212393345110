import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Box } from '@material-ui/core';
import React from 'react';

const useProgressDotStyles = makeStyles(() => createStyles({
  line: {
    border: '2px solid',
    position: 'absolute',
    borderColor: '#E6EAED',
    bottom: 14,
  },
  activeLine: {
    borderColor: 'rgb(40, 0, 240)',
    background: 'rgb(40, 0, 240)',
  },
}));

export const ProgressLine = ({
  left,
  right,
  filled,
  width = '50%',
} :{
  left?: boolean;
  right?: boolean;
  filled?: boolean;
  width?: string;
}) => {
  const styles = useProgressDotStyles();
  return (
    <Box
      width={width}
      left={left ? 0 : undefined}
      right={right ? 0 : undefined}
      className={cx(styles.line, filled && styles.activeLine)}
    />
  );
};
