import { saveAs } from 'file-saver';
import { endpoint, getToken } from './apiRequest';

/**
 * Download a file using `filename` specified in `content-disposition` header
 * @param {string} url             - URL to request
 * @param {Object} [fetchProps]    - Optional addtional props to pass to `fetch`
 * @example
 *     await downloadFile('https://example.com/myfile', { credentials: 'include' })
 */
export async function downloadFile(url: string, fetchProps: RequestInit = {}) {
  try {
    const fetchUrl = `${endpoint}/${url}`;

    const headers = new Headers();
    headers.append('accept', 'application/json, text/plain, */*');
    headers.set('authorization', getToken());
    const response = await fetch(fetchUrl, {
      ...fetchProps,
      headers,
    });

    if (!response || !response.ok || !response.headers) {
      // @ts-ignore
      throw new Error(response);
    }

    const blob = await response.blob();

    // Download the file
    saveAs(blob, 'bankReconciliation.csv');
  } catch (error) {
    // @ts-ignore
    throw new Error(error);
  }
}
