import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { CcBox } from '../../CcBox';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const useStyles = makeStyles({
  content: {
    background: '#FFFFFF',
    border: '0.6px solid #E5EAF9',
    boxShadow: '2px -1px 100px 1px rgba(127, 147, 174, 0.3)',
    borderRadius: 15,
  },
});

export const ApplicationContent = ({
  children, className,
}: Props) => {
  const styles = useStyles();
  return (
    <CcBox
      padding="l"
      className={cx(styles.content, className)}
    >
      {children}
    </CcBox>
  );
};
