import { AnyAction } from 'redux';

import {
  getApplicantAction,
} from './actionTypes';
import { IApplicantReducer } from './types';

const initialState: IApplicantReducer = {
  data: undefined,
  status: 'initial',
};

export function applicantReducer(
  state = initialState,
  action: AnyAction,
): IApplicantReducer {
  const { payload, type } = action;

  switch (type) {
    case getApplicantAction.REQUEST:
      return {
        ...state,
        status: 'loading',
      };
    case getApplicantAction.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getApplicantAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
