import { createTheme, createStyles } from '@material-ui/core';
import { primary, secondary } from './palette';
import MuiTextFieldOverrides from './MuiTextFieldOverrides';
import { typography } from './typography';
import MuiNewButtonOverrides from './MuiButtonNewOverrides';
import { breakpoints } from './breakpoints';

const defaultTheme = {
  overrides: {
    MuiFormControl: createStyles(MuiTextFieldOverrides),
    MuiTextField: createStyles(MuiTextFieldOverrides),
    MuiButton: createStyles(MuiNewButtonOverrides),
    MuiIconButton: createStyles({
      root: {
        '& > *': {
          pointerEvents: 'none',
        },
      },
    }),
  },
  palette: {
    primary,
    secondary,
  },
  typography,
  breakpoints,
};

export const MuiTheme = createTheme(defaultTheme);
