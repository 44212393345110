const START_CIRCLE_SIZE = 1077;
const END_CIRCLE_SIZE = 272;
const MARGIN_LEFT = 54;
const CONTENT_SIZE = 1020;

const FIX_HEADER_MARGIN_BOTTOM = 50;
const FIX_HEADER_TOP = 60;
export function cirsleAnimation(element) {
  const fullHeight = element.scrollHeight;
  const scrollPosition = element.scrollTop;

  const newCircleSize = START_CIRCLE_SIZE - scrollPosition
    * ((START_CIRCLE_SIZE - END_CIRCLE_SIZE) / (fullHeight - window.innerHeight));
  const newOffset = (newCircleSize - END_CIRCLE_SIZE) / 2 - 80;

  const xLadyPosition = CONTENT_SIZE / 2 + END_CIRCLE_SIZE / 2 + MARGIN_LEFT;
  const xCirclePosition = CONTENT_SIZE / 2 + END_CIRCLE_SIZE / 2 + MARGIN_LEFT;
  const oldSizes = window.getComputedStyle(element).getPropertyValue('background-size').split(',');
  /* eslint-disable */
  element.style['background-size'] = `${oldSizes[0]}, ${newCircleSize}px`;
  element.style['background-position'] = `calc(50% + ${xLadyPosition.toFixed(0)}px) calc(100% - 80px), calc(50% + ${xCirclePosition.toFixed(0)}px) calc(100% + ${newOffset.toFixed(0)}px)`;
  /* eslint-enable */
}

export const clearCircleAnimation = (element) => {
  element.style.removeProperty('background-size');
  element.style.removeProperty('background-position');
};

export const removeHeadingAnimation = (header) => {
  header.classList.remove('fixed', 'small-font');
  header.style.removeProperty('min-height');
};

/* eslint-disable no-param-reassign */
export function headingAnimation(header, container) {
  const scrollPosition = container.scrollTop;
  header.style.minHeight = header.style.minHeight || `${header.offsetHeight}px`;
  if (scrollPosition > 0 && scrollPosition > header.offsetTop - FIX_HEADER_TOP) {
    header.classList.add('fixed');
    if (scrollPosition > header.offsetTop - FIX_HEADER_TOP
      + header.childElementCount > 1 ? 0 : FIX_HEADER_MARGIN_BOTTOM) {
      header.classList.add('small-font');
    } else {
      header.classList.remove('small-font');
    }
  } else {
    header.classList.remove('fixed', 'small-font');
  }
}
/* eslint-enable no-param-reassign */

export function animateErrors(formElement) {
  const firstInvalidInput = formElement.querySelector(
    '.Mui-error',
  );
  if (!firstInvalidInput) {
    console.error('Something went wrong. Error field was not found.');
    return;
  }
  const error = firstInvalidInput
    .closest('.MuiFormControl-root')
    .querySelector('.MuiFormHelperText-root.Mui-error');
  if (error.classList.contains('FlashAnimation')) {
    error.classList.remove('FlashAnimation');
  }
  firstInvalidInput.scrollIntoView({
    block: 'center',
    behavior: 'smooth',
  });
  error.classList.add('FlashAnimation');
}

export const scrollToTop = (selector) => {
  const container = document.getElementsByClassName(selector)[0];
  if (container) {
    container.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
};
