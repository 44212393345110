import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useErrorContent } from './useErrorContent';
import { IS_LOCAL } from '../applicationConstants/envVariables';
import { reportGeneralError } from '../store/errorReport/actions';

export const useSendErrorNotification = ({ content }: { content: ReturnType<typeof useErrorContent> }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (IS_LOCAL) return;
    if (content.error?.includes('ChunkLoadError')) return;

    dispatch(reportGeneralError({
      data: {
        eventType: 'alert',
        content,
        traceId: content?.sentryEventId,
      },
    }));
  }, []);
};
