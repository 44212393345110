import {
  searchForExistingAccountAction,
  createSubmissionByAccountAction,
  getListOfLocationsLendersAction,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  CreateSubmissionByAccountLookup,
  createSubmissionBYExistingAccountRequest, getListOfLocationsLenders, SearchAccountType,
  searchForExistingAccountRequest,
} from '../apiRequests/existingAccount';
import { AccountLookupResult, IAccountLookupLocations } from '../../types/accountLookup';
import { ISubmission } from '../../types/submissions';

export const searchForExistingAccount = defThunkRequest<SearchAccountType, AccountLookupResult>({
  actionTypes: searchForExistingAccountAction,
  thunkSteps: [
    ({
      data,
    }) => {
      return searchForExistingAccountRequest(data);
    },
  ],
});

export const createSubmissionByExistingAccount = defThunkRequest<CreateSubmissionByAccountLookup, ISubmission>({
  actionTypes: createSubmissionByAccountAction,
  thunkSteps: [
    ({
      data: {
        body, location,
      },
    }) => {
      return createSubmissionBYExistingAccountRequest({
        body,
        location,
      });
    },
  ],
});

export const getListOfLocationsLendersAccountThunk = defThunkRequest<undefined, IAccountLookupLocations>({
  actionTypes: getListOfLocationsLendersAction,
  thunkSteps: [
    () => getListOfLocationsLenders(),
  ],
});
