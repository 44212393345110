import currency from 'currency.js';
import log from 'loglevel';

const usdFormatter = (value: any) => currency(value, { symbol: '$', precision: 2 });
const usdIntegerFormatter = (value: any) => currency(value, { symbol: '$', precision: 0 });

export const moneyFormatting = (
  price?: number | string,
  { integer, smartInteger }: { integer?: boolean, smartInteger?: boolean } = {},
) => {
  let value: number;
  if (price === null || price === undefined) {
    log.error('Value should be number or string');
    return 'none';
  }
  if (typeof price === 'string') {
    value = Number.parseInt(price);
  } else {
    value = price;
  }

  const dollarPrice = value / 100;
  let isInteger = integer;
  if (smartInteger) {
    isInteger = Number.isInteger(dollarPrice);
  }
  if (isInteger) {
    return usdIntegerFormatter(dollarPrice).format();
  }
  return usdFormatter(dollarPrice).format();
};

const formatDollarsToCents = (value: number | string) => {
  let newValue = (`${value}`);
  if (newValue && newValue.includes('.')) {
    newValue = newValue.substring(0, newValue.indexOf('.') + 3);
  }

  return newValue ? Math.round(parseFloat(newValue) * 100) : 0;
};

export const usdToCents = (value: number | string) => {
  return formatDollarsToCents(value);
};
