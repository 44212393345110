export function getDeviceInfo() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const orientation = width > height ? 'landscape' : 'portrait';
  const screenDensity = window.devicePixelRatio;
  const screenDpi = screenDensity * 96; // 96 is the default DPI for most browsers

  return {
    screen_width_pixels: width,
    screen_height_pixels: height,
    orientation,
    screen_density: screenDensity,
    screen_dpi: screenDpi,
  } as any;
}
