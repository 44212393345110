import get from 'lodash/get';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { getKbaChallengeAction, submitKbaChallengeAction } from './actionTypes';
import { IResponse } from '../../types/otherData';
import { KbaAnswer, KbaChallenge } from '../../types/kba';
import { getKbaChallengeRequest, submitKbaChallengeRequest } from '../apiRequests/kbaQuestions';
import { IApplication } from '../../types/application';
import { setRequestedAmount } from '../application';

export const getKbaChallengeThunk = defThunkRequest<{
  lenderApplicationId: string;
}, IResponse<KbaChallenge>>({
  actionTypes: getKbaChallengeAction,
  thunkSteps: [
    ({
      data: {
        lenderApplicationId,
      },
    }) => getKbaChallengeRequest(lenderApplicationId),
  ],
});

export const submitKbaChallengeThunk = defThunkRequest<{
  lenderApplicationId: string;
  answers: Array<KbaAnswer>
}, IResponse<IApplication>>({
  actionTypes: submitKbaChallengeAction,
  thunkSteps: [
    ({
      data: {
        lenderApplicationId,
        answers,
      },
    }) => submitKbaChallengeRequest(lenderApplicationId, answers),
    ({ prevResponse, dispatch }) => {
      const loanAmount = get(prevResponse, 'data.softpullLoanAmount');
      const creditLimit = get(prevResponse, 'data.softpullCreditLimit');

      dispatch(setRequestedAmount({ loanAmount, creditLimit, minAmount: Math.min(loanAmount, creditLimit) }));
      return prevResponse;
    },
  ],
});
