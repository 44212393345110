import {
  getLenderOfferRequest,
  GetLenderOffersProps,
  getLenderOffersRequest,
  setHfdOfferRequest,
} from 'store/apiRequests';
import {
  getLenderOfferAction,
  getLenderOffersAction,
  RESET_OFFERS,
  setLenderOfferAction,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { loadSubmission } from '../submission';
import { IPaginationResponse } from '../../types/otherData';
import { IOffer } from '../../types/application';

export const getOffersByLender = defThunkRequest<
  GetLenderOffersProps,
  IPaginationResponse<IOffer>
  >({
    actionTypes: getLenderOffersAction,
    thunkSteps: [
      ({
        data,
      }) => getLenderOffersRequest(data),
    ],
  });

export const getOfferById = defThunkRequest<{
  id: string;
}>({
  actionTypes: getLenderOfferAction,
  thunkSteps: [
    ({
      data: { id },
    }) => getLenderOfferRequest(id),
  ],
});

export const setLenderOfferThunk = defThunkRequest<{
  id: string;
  offerId: string;
  submissionId: string;
}>({
  actionTypes: setLenderOfferAction,
  thunkSteps: [
    ({
      data: { id, offerId },
    }) => setHfdOfferRequest(id, { offerId }),
    ({
      data: { submissionId },
      history,
      prevResponse,
      dispatch,
    }) => {
      if (prevResponse.data.success) {
        dispatch(loadSubmission({
          data: { submissionId, suppressProceed: true },
          history,
        }));
      }
      return prevResponse;
    },
  ],
});

export const resetOffers = () => {
  return {
    type: RESET_OFFERS,
  };
};
