import React from 'react';
import { CcBox, SpacingType } from '../../CcBox';

type Props = {
  children?: React.ReactNode;
  margin?: SpacingType
};

export const ApplicationFooter = ({
  children, margin,
}: Props) => {
  return (
    <CcBox margin={margin}>
      {children}
    </CcBox>
  );
};
