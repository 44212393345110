import { AnyAction } from 'redux';
import { getRegionActions, getRegionsActions, RESET_REGIONS } from './actionTypes';
import { RegionsReducer } from './types';

const initialLocationsState: RegionsReducer = {
  data: { items: [], total: 0, offset: 0 },
  isLoading: false,
  isLoaded: false,
  useDictionary: false,
  error: null,
};

export function regionsReducer(state = initialLocationsState, action: AnyAction): RegionsReducer {
  const { payload, type } = action;
  switch (type) {
    case getRegionsActions.REQUEST:
      return {
        ...state,
        useDictionary: payload?.useDictionary,
        isLoading: true,
        error: null,
      };
    case getRegionsActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
        isLoaded: true,
      };
    case getRegionsActions.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isLoaded: true,
      };
    case getRegionActions.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case getRegionActions.SUCCESS:
      return {
        ...state,
        data: {
          items: [payload.data],
          total: 0,
          offset: 0,
        },
        isLoading: false,
        isLoaded: true,
      };
    case getRegionActions.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isLoaded: true,
      };
    case RESET_REGIONS:
      return {
        ...state,
        ...initialLocationsState,
      };
    default:
      return {
        ...state,
      };
  }
}
