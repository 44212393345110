import { defActionTypes } from '../../utils/defActionTypes';

export const getTransactionsAction = defActionTypes('get_transactions_action');

export const getTransactionAction = defActionTypes('get_transaction_action');
export const confirmRefundTransactionAction = defActionTypes('confirm_refund_transaction_action');
export const createTransactionAction = defActionTypes('create_transaction_action');

export const refundLenderApplicationAction = defActionTypes('refund_lender_Application');
export const voidTransactionAction = defActionTypes('void_transaction');
export const confirmSigningTransactionAction = defActionTypes('confirm_signing');
export const retryTransactionAction = defActionTypes('retry_Transaction_Action');
export const fundSandboxTransactionAction = defActionTypes('fund_sandbox_Transaction_Action');
export const setTransactionOwnerAction = defActionTypes('set_transaction_owner_Action');
export const generateTransactionPdfAction = defActionTypes('generate_transaction_pdf_action');
export const generateAEPreSignedPdfAction = defActionTypes('generate_pre_signed_pdf_action');

export const cancelTransactionAction = defActionTypes('cancel_transaction_action');
export const replaceTransactionAction = defActionTypes('replace_transaction_action');

export const RESET_TRANSACTIONS = 'RESET_TRANSACTIONS';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
