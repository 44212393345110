import React, { useEffect } from 'react';
import cx from 'classnames';
import { Box, Button, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTextMapper } from '../../hooks/useTextMapper';
import { CcBox } from '../../components/CcBox';
import CredCompareSVGIcon from '../../components/CredCompareSVGIcons';
import { CcTypography } from '../../components/CcTypography';

const useStyles = makeStyles({
  container: {
    zIndex: 10000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(9, 2, 36, 0.7)',
  },
  content: {
    display: 'flex',
    background: '#FFF',
    borderRadius: 5,
    width: 960,
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
  },
  contentBoxMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
});

export const VersionUpdateModal = () => {
  const styles = useStyles();
  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.up('tablet'));
  const getText = useTextMapper();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <CcBox padding="xs" className={styles.container}>
      <CcBox className={styles.content} padding="m">
        <CcBox padding={{ right: 's' }}>
          <CredCompareSVGIcon name="animatedLogo" width={34} height={34} />
        </CcBox>
        <Box className={cx(isTablet ? styles.contentBox : styles.contentBoxMobile)}>
          <Box>
            <CcTypography variant="subtitle2">
              {getText('liveUpdateModal.title')}
            </CcTypography>
            <CcBox margin={{ top: 'xs' }}>
              <CcTypography variant="caption" customColor="rgba(9, 2, 36, 0.7)">
                {getText('liveUpdateModal.subtitle')}
              </CcTypography>
            </CcBox>
          </Box>
          {
            isTablet
              ? (
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  {getText('liveUpdateModal.button')}
                </Button>
              )
              : (
                <CcBox margin={{ top: 'm' }}>
                  <Button
                    onClick={() => {
                      window.location.reload();
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    {getText('liveUpdateModal.button')}
                  </Button>
                </CcBox>
              )
          }
        </Box>
      </CcBox>
    </CcBox>
  );
};
