import property from 'lodash/property';
import strings from 'assets/strings/index';
import { IS_DEV, IS_LOCAL } from '../applicationConstants/envVariables';

export default function getText(textPath: string) {
  const text = (property(textPath)(strings.general) as string);

  if (!text) {
    return IS_DEV || IS_LOCAL ? textPath : '';
  }

  return text;
}
