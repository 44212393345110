import {
  getUserInfo,
  updateUserInfo,
  logoutRequest,
  resetPasswordRequest,
  getRegionApiRequest,
  getInviteLocationRequest,
  authByInviteRequest,
  getInviteNetworkRequest,
  getLocationTokenRequest,
  AuthByInviteBody, authCheckForExistingUser,
} from 'store/apiRequests';
import {
  authActions,
  userActions,
  resetPasswordActions,
  UNAUTHORIZED_USER,
  logoutActions,
  loadInviteLocationActions,
  authByInviteActions,
  getLocationTokenActions, checkForExistingUserActions,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { USER_ROLES, USER_ROLES_TYPE } from '../../applicationConstants';
import { setAlterToken } from '../../utils/apiRequest';
import { IUser, UpdateUserInfo } from '../../types/user';
import { ILocation } from '../../types/location';
import { INetwork } from '../../types/network';
import { IRegion } from '../../types/region';
import { includesInUnion } from '../../utils/includesInUnion';

export function handleUnauthorizedUser(payload: any) {
  return {
    type: UNAUTHORIZED_USER,
    payload,
  };
}

export type UserResponse = {
  user: IUser;
  region?: IRegion;
  location?: ILocation;
  network?: INetwork;
};

export const loadUserInformation = defThunkRequest<unknown, UserResponse>({
  actionTypes: authActions,
  thunkSteps: [
    () => getUserInfo(),
    async ({ prevResponse }) => {
      if (
        includesInUnion([USER_ROLES.regionalSales, USER_ROLES.regionalManager], prevResponse.data.user.role)) {
        const region = await getRegionApiRequest(prevResponse.data.user.region);

        return {
          ...prevResponse,
          data: {
            ...prevResponse.data,
            region: region.data,
          },
        };
      }
      return prevResponse;
    },
  ],
});

export const updateUserInformation = defThunkRequest<UpdateUserInfo>({
  actionTypes: userActions,
  thunkSteps: [
    ({ data }) => updateUserInfo(data),
  ],
});

export const logoutThunk = defThunkRequest<undefined>({
  actionTypes: logoutActions,
  thunkSteps: [
    () => logoutRequest(),
  ],
});

export const resetPasswordThunk = defThunkRequest({
  actionTypes: resetPasswordActions,
  thunkSteps: [
    () => resetPasswordRequest(),
  ],
});

export const loadInviteLocationThunk = defThunkRequest<{invite: string}>({
  actionTypes: loadInviteLocationActions,
  thunkSteps: [
    async ({ data: { invite } }: any) => {
      const location = await getInviteLocationRequest(invite);
      const network = await getInviteNetworkRequest(invite);
      return {
        location: location.data,
        network: network.data,
        invite,
      };
    },
  ],
});

export const authByInviteThunk = defThunkRequest<AuthByInviteBody>({
  actionTypes: authByInviteActions,
  thunkSteps: [
    ({ data }) => authByInviteRequest(data),
  ],
});

export const getLocationTokenActionsThunk = defThunkRequest<{locationId: string}>({
  actionTypes: getLocationTokenActions,
  thunkSteps: [
    async ({ data: { locationId } }: any) => {
      const response = await getLocationTokenRequest(locationId) as any;
      setAlterToken(response.data.token);
      return null;
    },
  ],
});

export const checkForExistingUserThunk = defThunkRequest<unknown, {
  canConnect: boolean,
  restrictDomain: string,
  ssoProvider: string,
  role: USER_ROLES_TYPE
}>({
  actionTypes: checkForExistingUserActions,
  thunkSteps: [
    () => authCheckForExistingUser(),
  ],
});
