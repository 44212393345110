import { IPaginationData } from '../types/otherData';

export const buildSortByPagination = (data?: Pick<IPaginationData, 'sortField' | 'sortDirection'>) => {
  if (!data || !data?.sortField) return undefined;

  const {
    sortField,
    sortDirection,
  } = data;

  const direction = sortDirection === 'reversal' ? '' : '-';

  return `${direction}${sortField}`;
};
