import { AnyAction } from 'redux';
import { getQuotesActions } from './actionTypes';
import { QuotesReducer } from './types';

const initialLocationsState: QuotesReducer = {
  data: undefined,
  status: 'initial',
};

export function quotesReducer(state = initialLocationsState, action: AnyAction): QuotesReducer {
  const { payload, type } = action;
  switch (type) {
    case getQuotesActions.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getQuotesActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getQuotesActions.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
