import React from 'react';
import log from 'loglevel';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useErrorContent } from '../../hooks/useErrorContent';
import { ALLOWED_MODE, COLORS } from '../../applicationConstants';
import { CcLink } from '../CcLink';
import { copyTextToClipboard } from '../../utils/copyToClipboard';
import { useModalControl } from '../../hooks/useModalControll';
import { useTextMapper } from '../../hooks/useTextMapper';
import { MoreInfoModal } from './MoreInfo';

type Props = {
    error: any;
    errorInfo: any;
    onRefresh: () => void,
};

const useStyles = makeStyles(() => createStyles({
  button: {
    textTransform: 'none',
  },
  container: {
    background: COLORS.lightestBlackRock,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 20,
  },
}));

const AtomicErrorComponent = ({
  error, errorInfo, onRefresh,
}: Props) => {
  const styles = useStyles();
  const getText = useTextMapper();
  const [openMoreInfo, infoController] = useModalControl();

  const networkMode = useSelector((state) => state.auth.network?.mode);
  const content = useErrorContent({ error, errorInfo });
  const handleCopyContent = () => {
    const strContent = JSON.stringify(content, null, ' ');

    copyTextToClipboard(strContent);
  };

  return (
    <Box className={styles.container}>
      {networkMode && networkMode !== ALLOWED_MODE.prod && (
        <>
          <CcLink
            component={Button}
            className={styles.button}
            text={getText('global.errorPage.moreInfo')}
            color={COLORS.lightGray}
            onClick={infoController.open}
          />
          <CcLink
            component={Button}
            className={styles.button}
            text={getText('global.errorPage.copy')}
            color={COLORS.lightGray}
            onClick={handleCopyContent}
          />
          <Button
            onClick={onRefresh}
            aria-label="Refresh error in atomic error component"
          >
            Refresh
          </Button>
        </>
      )}
      {
        openMoreInfo && (
        <MoreInfoModal
          contentItems={content}
          onClose={infoController.close}
        />
        )
      }
    </Box>
  );
};

export class AtomicErrorBoundary extends React.Component<any, {hasError: boolean, info?: any, error: any}> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false, error: '' };
  }

  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ hasError: true, error, info });
    log.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <AtomicErrorComponent
          error={this.state.error}
          errorInfo={this.state.info}
          onRefresh={() => this.setState({ hasError: false })}
        />
      );
    }
    return this.props.children;
  }
}

export function withAtomicErrorBoundary<T>(Component: any) {
  return (props: T) => (
    <AtomicErrorBoundary>
      <Component {...props} />
    </AtomicErrorBoundary>
  );
}
