import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CcBox } from '../CcBox';

const useStyles = makeStyles((theme) => createStyles({
  buttonsGroupContainer: {
    width: '100%',
    display: 'flex',
    '& > *:first-child': {
      marginRight: 8,
    },
    '& > *:last-child': {
      marginLeft: 8,
    },
    '& > *:only-child': {
      marginRight: 0,
      marginLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      '& > *:first-child': {
        marginRight: 0,
      },
      '& > *:last-child': {
        marginLeft: 0,
        marginBottom: 16,
      },
      '& > *:only-child': {
        marginBottom: 0,
      },
    },
  },
}));

export const ModalButtonsGroup = ({ children }: any) => {
  const styles = useStyles();
  return (
    <CcBox margin={{ top: 'm' }} className={styles.buttonsGroupContainer}>
      {children}
    </CcBox>
  );
};
