import { useHistory } from 'react-router-dom';
import { toObject, toParams } from 'utils/params';
import isEmpty from 'lodash/isEmpty';
import { History, Path } from 'history';
import { useMemo } from 'react';

export interface ICustomHistory extends History {
  push(path: Path, state?: any, keepSearch?: any): void;

}

export const useCustomHistory = (): ICustomHistory => {
  const history = useHistory();
  return useMemo(() => {
    return {
      ...history,
      push: (pathName: string, state?:any, keepSearch: any = {}) => {
        const [path, search] = pathName.split('?');
        const newSearchObj = {
          ...toObject(history.location.search),
          ...toObject(search),
        };

        const clearedSearchObject = Object
          .keys(newSearchObj)
          .reduce((acc: any, key: string) => {
            if (isEmpty(keepSearch) || keepSearch[key] === true) {
              acc[key] = newSearchObj[key];
            }
            return acc;
          }, {});
        history.push(`${path}${toParams(clearedSearchObject)}`, state);
      },
    };
  }, [history]);
};
