import moment from 'moment';

export const paramsToDictionary = (search: string) => {
  return search.replace('?', '').split('&').reduce((acc: any, x) => {
    const [key, value] = x.split('=');

    acc[key] = decodeURIComponent(value);

    if (key === 'errorObject') {
      acc[key] = JSON.parse(acc[key]);
    }

    return acc;
  }, {});
};

export const useErrorContent = ({
  error,
  errorInfo,
  additionalContent,
}: {
  error?: object | string
  errorInfo?: object | string
  additionalContent?: any;
}): {
  time: string;
  timeUTCTimestamp: string;
  error?: string;
  errorInfo?: string;
  sentryEventId?: string;
  [key: string]: any;
} => {
  const resError = error && typeof error === 'object' ? JSON.stringify(error, null, ' ') : error;
  const resInfo = errorInfo && typeof errorInfo === 'object' ? JSON.stringify(errorInfo, null, ' ') : errorInfo;
  return {
    time: moment().format('MM/DD/YYYY HH:mm'),
    timeUTCTimestamp: moment().unix(),
    error: resError,
    errorInfo: resInfo,
    sentryEventId: additionalContent?.sentryEventId,
    ...additionalContent,
  };
};
