import React from 'react';
import DOMPurify from 'dompurify';

const buildTemplateString = (key: string) => `{{ ${key} }}`;

const template = (text: string) => {
  return (params: any = {}) => {
    const keys = Object.keys(params);

    return keys.reduce((acc, key) => {
      if (params[key] === null || params[key] === undefined) {
        return acc;
      }
      const templateString = buildTemplateString(key);
      const param = params[key].toString();
      let tempAcc = acc;
      while (tempAcc.includes(templateString)) {
        tempAcc = tempAcc.replace(templateString, param);
      }
      return tempAcc;
    }, text);
  };
};

export const DangerousComponentInjector = ({
  text,
  params = {},
  component: Component = 'span',
  elementProps = {},
  ...props
}: any) => {
  const compiled = template(text);

  // Escape text in all fields of the params object
  const escapedParams = params && Object
    .keys(params)
    .reduce((acc, key) => {
      if (typeof params[key] === 'string' || typeof params[key] === 'object') {
        return {
          ...acc,
          [key]: params[key] ? DOMPurify.sanitize(params[key].toString()) : params[key],
        };
      }

      return {
        ...acc,
        [key]: params[key],
      };
    }, {});

  /* eslint-disable */
  return (
    <Component
      {...props}
      {...elementProps}
      dangerouslySetInnerHTML={{
        __html: compiled(escapedParams),
      }}
    />
  );
  /* eslint-enable */
};

export const dynamicStringCompiler = ({ text, params = {} }: {text: string | string[], params: any}): string => {
  if (Array.isArray(text)) {
    return '';
  }
  const compiled = template(text);

  // Escape text in all fields of the params object
  const escapedParams = params && Object
    .keys(params)
    .reduce((acc, key) => {
      return {
        ...acc,
        [key]: DOMPurify.sanitize(params[key].toString()),
      };
    }, {});

  return compiled(escapedParams);
};
