import * as Sentry from '@sentry/react';
import {
  LENDER_APPLICATION_UPDATE,
} from './application';
import {
  SUBMISSION_UPDATE,
} from './submission';

import {
  UPDATE_TRANSACTION,
} from './transactions';
import { includesInUnion } from '../utils/includesInUnion';

const FORBIDDEN_OBJECT_KEYS = [
  'applicant',
  'history',
  'lenderExpose',
  'raw',
];

const FORBIDDEN_FIELD_KEYS = [
  'receipt',
  'printedReceipt',
  'externalId',
];

const filterForbiddenData = (obj: any) => {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (FORBIDDEN_OBJECT_KEYS.includes(key) && typeof obj[key] === 'object') {
      newObj[key] = '[filtered]';
      return;
    }
    if (FORBIDDEN_FIELD_KEYS.includes(key) && typeof obj[key] !== 'object') {
      newObj[key] = '[filtered]';
      return;
    }
    if (Array.isArray(obj[key])) {
      newObj[key] = obj[key].map(filterForbiddenData);
      return;
    }
    if (typeof obj[key] === 'object' && !!obj[key]) {
      newObj[key] = filterForbiddenData(obj[key]);
      return;
    }
    newObj[key] = obj[key];
  });
  return newObj;
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type
      && (action.type.toLowerCase().includes('success')
      || includesInUnion([
        UPDATE_TRANSACTION,
        LENDER_APPLICATION_UPDATE,
        SUBMISSION_UPDATE,
      ], action.type))
    ) {
      return filterForbiddenData(action);
    }

    return { type: action.type, history: !!action.history };
  },
  stateTransformer: () => {
    return { };
  },
});
