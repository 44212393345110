import { AnyAction } from 'redux';

import {
  getUsersAction,
} from './actionTypes';

const initialState = {
  data: { },
  isLoaded: false,
  error: null,
};

export function usersReducer(state = initialState, action: AnyAction) {
  const { type } = action;
  switch (type) {
    case getUsersAction.SUCCESS: {
      const { payload: { data } } = action;
      return {
        ...state,
        isLoaded: true,
        data: {
          ...data,
          items: data.items,
        },
      };
    }
    case getUsersAction.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
