import {
  getApplicationsListRequest,
} from 'store/apiRequests';
import {
  TRANSACTION_STAGE,
  TRANSACTION_STATUSES,
} from 'applicationConstants';
import {
  getApplicationsAction,
  newTransactionAction,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { IPaginationData, IPaginationResponse } from '../../types/otherData';
import { getLenderApplication } from '../application';
import { createTransaction, CreateTransactionsProps } from '../transactions';
import { loadSubmission } from '../submission';
import { ITransaction } from '../../types/transaction';
import { ISubmission } from '../../types/submissions';
import { includesInUnion } from '../../utils/includesInUnion';

export const getApplicationsList = defThunkRequest<IPaginationData & {
  isCharged?: boolean;
}, IPaginationResponse<ISubmission>>({
  actionTypes: getApplicationsAction,
  thunkSteps: [
    ({ data }) => getApplicationsListRequest(data),
  ],
});

type ChargeProps = {
  submissionId?: string;
  lenderApplicationId?: string;
} & CreateTransactionsProps;

export const chargeLenderApplication = defThunkRequest<ChargeProps, ITransaction>({
  actionTypes: newTransactionAction,
  thunkSteps: [
    async ({
      data,
      dispatch,
    }) => {
      return new Promise((res, rej) => {
        dispatch(createTransaction({
          data,
          onSuccess: res,
          onFailure: rej,
        },
        ));
      });
    },
    ({
      prevResponse,
      data: { waitForPending, submissionId, lenderApplicationId },
      dispatch,
      history,
    }) => {
      if (waitForPending && !lenderApplicationId && submissionId) {
        dispatch(loadSubmission({
          data: {
            submissionId,
            suppressWaiting: true,
            suppressProceed: true,
          },
          history,
        }));
      }
      if (lenderApplicationId && (
        !includesInUnion([
          TRANSACTION_STATUSES.error,
          TRANSACTION_STATUSES.config_error,
        ], prevResponse.data.status)
          && prevResponse.data.status !== TRANSACTION_STAGE.declined)) {
        dispatch(getLenderApplication({
          data: {
            id: lenderApplicationId,
            suppressWaiting: true,
          },
          history,
        }));
      }
      return prevResponse;
    },
  ],
});
