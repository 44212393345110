import React from 'react';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { useSelector } from 'react-redux';
import { paramsToDictionary } from 'hooks/useErrorContent';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { ErrorBoundaryComponent } from './ErrorBoundary';

export const useAdditionalErrorContent = () => {
  const location = useLocation();
  const { code, state: searchState, ...params } = paramsToDictionary(location.search);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const userId = userInfo?.id;
  const locationId = userInfo?.location;
  const networkId = userInfo?.network;
  return {
    time: moment().format('MM/DD/YYYY HH:mm'),
    userId,
    locationId,
    networkId,
    ...params,
  };
};

export const ErrorComponent = () => {
  const additionalContent = useAdditionalErrorContent();

  const applicationRequest = useSelector((state) => state.application.data);
  const history = useCustomHistory();
  const shouldGoBack = !!applicationRequest?.application?.id;

  const handleGoToHome = () => {
    if (history.location.pathname === '/') {
      window.location.reload();
      return;
    }
    history.push('/', {});
  };

  return (
    <ErrorBoundaryComponent
      resetError={handleGoToHome}
      additionalErrorContent={additionalContent}
      useGoBackText={shouldGoBack}
    />
  );
};
