import { AnyAction } from 'redux';
import * as Papa from 'papaparse';

import {
  getSupportMerchantReportsAction,
} from './actionTypes';
import { ISupportMerchantReportsReducer } from './types';

const initialTransactionsState: ISupportMerchantReportsReducer = {
  data: undefined,
  status: 'initial',
  error: null,
};

export function supportMerchantReportsReducer(
  state = initialTransactionsState,
  action: AnyAction,
): ISupportMerchantReportsReducer {
  const { payload, type } = action;

  switch (type) {
    case getSupportMerchantReportsAction.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getSupportMerchantReportsAction.SUCCESS: {
      const parsedInfo = Papa.parse<Array<string>>(payload.data);
      return {
        ...state,
        data: {
          original: payload.data,
          headerRow: parsedInfo.data[0],
          rows: parsedInfo.data.slice(1),
        },
        status: 'loaded',
      };
    }
    case getSupportMerchantReportsAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return state;
  }
}
