import { toParams } from 'utils/params';
import { apiRequest } from '../../utils/apiRequest';
import { IPaginationData } from '../../types/otherData';

export const createLocationApiRequest = (body: {
    name: string,
    region?: string | null,
    marketingName?: string
}) => apiRequest('location', {
  method: 'POST',
  body,
});
export const editLocationApiRequest = ({ id, ...body }: {
    id: string;
    name: string;
    region?: string | null;
    marketingName?: string;
}) => apiRequest(`location/${id}`, {
  method: 'POST',
  body,
});

export const setLocationStatusApiRequest = ({ id, status }: any) => apiRequest(`location/${id}/status`, {
  method: 'POST',
  body: { status },
});

export const getLocationsListApiRequest = (
  data?: IPaginationData,
  locationId?: string,
) => apiRequest(
  `location${toParams({
    start: data?.start,
    count: data?.count,
    name: data?.search,
    status: ['init', 'active', 'frozen'],
    location: locationId,
  })}`,
  {
    method: 'GET',
  },
);

export const getLocationApiRequest = (
  locationId?: string,
) => apiRequest(
  `location/${locationId}`,
  {
    method: 'GET',
  },
);

export const getInviteLocationRequest = (
  invite: string,
) => apiRequest(
  `location/invite${toParams({
    invite,
  })}`,
  {
    method: 'GET',
  },
);

export const getInviteNetworkRequest = (
  invite: string,
) => apiRequest(
  `network/invite${toParams({
    invite,
  })}`,
  {
    method: 'GET',
  },
);
