import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

export function toParams(object?: any) {
  if (!object || isEmpty(object)) return '';

  return `?${queryString.stringify(object)}`;
}

export function toObject(search?: string): {[key: string]: any} {
  if (!search) return {};
  return queryString.parse(search);
}
