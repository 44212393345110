import { AnyAction } from 'redux';

import {
  networksActions,
} from './actionTypes';
import { INetworksReducer } from './types';

const initialLocationsState: INetworksReducer = {
  data: {
    items: [],
    total: 0,
    offset: 0,
  },
  status: 'initial',
};

export function networksReducer(state = initialLocationsState, action: AnyAction): INetworksReducer {
  const { payload, type } = action;
  switch (type) {
    case networksActions.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case networksActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case networksActions.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
