import {
  getSsoDomainsRequest,
} from '../apiRequests';
import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  getSsoDomainsActions,
} from './actionTypes';

export const getSsoDomainsThunk = defThunkRequest<unknown>({
  actionTypes: getSsoDomainsActions,
  thunkSteps: [
    () => getSsoDomainsRequest(),
  ],
});
