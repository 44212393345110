import { AnyAction } from 'redux';

import {
  resetLenderAccountType,
  getLenderAccountAction,
} from './actionTypes';
import { ILenderAccountReducer } from './types';
import { RESET_APP } from '../app';
import { requestIncreasedAmountAction } from '../application';

const initialState: ILenderAccountReducer = {
  data: undefined,
  status: 'initial',
};

export function lenderAccountReducer(
  state = initialState,
  action: AnyAction,
): ILenderAccountReducer {
  const { payload, type } = action;

  switch (type) {
    case getLenderAccountAction.REQUEST:
      return {
        ...state,
        status: 'loading',
      };
    case getLenderAccountAction.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getLenderAccountAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    case requestIncreasedAmountAction.SUCCESS:
      if (!state.data) { return state; }
      return {
        ...state,
        data: {
          ...state.data,
          creditLimit: payload.data.creditLimit,
          availableCredit: payload.data.availableCredit,
        },
      };
    case RESET_APP:
    case resetLenderAccountType:
      return initialState;
    default:
      return state;
  }
}
