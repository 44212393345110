import { defThunkRequest } from '../../utils/defAsyncAction';
import { getBISupportReportsRequest, BiSupportReportsParams } from '../apiRequests';
import {
  getSupportMerchantReportsAction,
} from './actionTypes';

export const getSupportMerchantReportsThunk = defThunkRequest<BiSupportReportsParams>({
  actionTypes: getSupportMerchantReportsAction,
  thunkSteps: [
    async ({ data }) => {
      return getBISupportReportsRequest(data);
    },
  ],
});
