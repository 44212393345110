import React from 'react';
import { useSelector } from 'react-redux';
import { CcTypography } from '../../components/CcTypography';
import { COLORS } from '../../applicationConstants';

export const GuestContent = () => {
  const location = (useSelector((state) => state.auth.location));

  return (
    <div>
      <CcTypography
        customColor={COLORS.lightGray}
        variant="caption"
      >
        {location?.marketingName}
      </CcTypography>
    </div>
  );
};
