import React, { memo } from 'react';
import cx from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ICONS from './icons';

export type IconNameType = keyof typeof ICONS;

export type CredCompareSVGIconProps = {
  name: IconNameType;
  color?: string;
  role?: string;
  title?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  style?: any;
  /* position in a button to add correct margin */
  position?: 'left' | 'right';
};

export const useStyles = makeStyles(() => createStyles({
  icon: {
    width: ({ width }: {width?: number | string; height?: number | string;}) => width,
    height: ({ height }: {width?: number | string; height?: number| string}) => height,
    display: 'inline-block',
    verticalAlign: 'middle',
    transform: 'translate(0, -1px)',
  },
}));

const CredCompareSVGIcon = ({
  name, role, title, color, width, height, className, style, position,
}: CredCompareSVGIconProps) => {
  const styles = useStyles({ width, height });
  const IconTag = ICONS[name];

  if (!IconTag) {
    throw new Error(`CredCompareSVGIcon can't find ${name} icon`);
  }

  return (
    <IconTag
      style={style}
      className={cx(styles.icon, className, position)}
      role={role}
      title={title}
      key={name}
      fill={color}
    />
  );
};

CredCompareSVGIcon.displayName = 'CredCompareSVGIcon';

CredCompareSVGIcon.defaultProps = {
  color: '',
  role: 'img',
  title: '',
  width: 20,
  height: 20,
};

export default memo(CredCompareSVGIcon);
