import get from 'lodash/get';

export const requestWithTimeout = (request: Function, timeout: number = 0) => new Promise((res, rej) => {
  setTimeout(async () => {
    try {
      const result = await request();
      res(result);
    } catch (e) {
      rej(e);
    }
  }, timeout);
});

interface IProps {
  request: Function;
  condition: Function;
  failureMessage?: string;
  every?: number;
  timeout?: number;
  delays?: Array<number> | null;
}

const getDelay = ({ counter, every, delays }: {counter: number; every: number; delays?: Array<number> | null;}) => {
  if (counter === 0) return 0;
  if (Array.isArray(delays)) {
    return delays[counter] * 1000;
  }

  return every * 1000;
};

export const waitWhileCondition = async ({
  request, condition, failureMessage, every = 5, timeout = 120, delays = null,
}: IProps): Promise<any> => {
  // By default this function makes request every `every` seconds until `condition` will return false or until `timeout`
  // will be reached.
  // If `delays` provided with an array, function instead will make requests with provided delays. `every`
  // and `timeout` will be ignored.
  let res;
  let data;
  let prevData;
  let counter = 0;
  const startedAt = Date.now();
  do {
    const delay = getDelay({ counter, every, delays });
    // eslint-disable-next-line no-await-in-loop
    res = await requestWithTimeout(request, delay);
    prevData = data;
    data = get(res, 'data');
    counter += 1;
    if (Array.isArray(delays) && counter >= delays.length) break;
    if (!delays && Date.now() > (startedAt + (timeout * 1000))) break;
  } while (condition(data, prevData));

  if (condition(data, prevData) && failureMessage) {
    throw new Error(failureMessage);
  }
  return res;
};
