import { useCallback, useEffect, useRef } from 'react';
import {
  headingAnimation, removeHeadingAnimation,
} from 'utils/animation';
import { useMediaQuery } from '@material-ui/core';

export const useHeaderAnimation = () => {
  const headerRef = useRef(null);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('desktop'));
  const animateCircle = useCallback(() => {
    if (!isDesktop) {
      if (headerRef.current) {
        removeHeadingAnimation(headerRef.current);
      }
      return;
    }
    if (headerRef.current) {
      const element = document.getElementById('application-page');
      if (!element) return;
      headingAnimation(headerRef.current, element);
    }
  }, [isDesktop, headerRef.current]);

  useEffect(() => {
    window.addEventListener('scroll', animateCircle, true);

    return () => {
      window.removeEventListener('scroll', animateCircle, true);
    };
  }, [animateCircle]);
  return headerRef;
};
