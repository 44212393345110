import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressComponent } from './ProgressComponent';

type Props = {
  step: number;
  steps: number;
  onGoBack?: () => void;
};

const useSideBarStyles = makeStyles({
  sidebar: {
    position: 'absolute',
    left: 48,
    top: '280px',
    pointerEvents: 'none',
  },
});

export const ApplicationProgress = ({
  steps,
  step,
  onGoBack,
}: Props) => {
  const styles = useSideBarStyles();

  return (
    <div className={styles.sidebar}>
      <ProgressComponent
        steps={steps}
        step={step}
        onGoBack={onGoBack}
      />
    </div>
  );
};
