import {
  networksActions, setNetworkActions,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { getNetworksListApiRequest, setNetworkApiRequest } from '../apiRequests/networks';

export const getNetworkListThunk = defThunkRequest<undefined>({
  actionTypes: networksActions,
  thunkSteps: [
    () => getNetworksListApiRequest(),
  ],
});

export const setNetworkThunk = defThunkRequest<{ id: string }>({
  actionTypes: setNetworkActions,
  thunkSteps: [
    ({ data: { id } }) => setNetworkApiRequest(id),
  ],
});
