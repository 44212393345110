import { AnyAction } from 'redux';

import {
  getKbaChallengeAction,
} from './actionTypes';
import { IBaseReducer } from '../../types/otherData';
import { KbaChallenge } from '../../types/kba';

interface IKbaReducer extends IBaseReducer{
  data?: KbaChallenge;
}

const initialState: IKbaReducer = {
  data: undefined,
  status: 'initial',
  error: null,
};

export function kbaReducer(state = initialState, action: AnyAction): IKbaReducer {
  const { payload, type } = action;
  switch (type) {
    case getKbaChallengeAction.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getKbaChallengeAction.SUCCESS:
      return {
        ...state,
        status: 'loaded',
        data: payload.data,
      };
    case getKbaChallengeAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
