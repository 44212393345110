import { useDispatch } from 'react-redux';
import React, { Suspense, useEffect } from 'react';
import { setLoader } from '../store/app';

const SuspenseComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoader(true));
    return () => {
      dispatch(setLoader(false));
    };
  }, [dispatch]);
  return null;
};

export const SuspenseContext = ({ children }: {children: React.ReactNode}) => {
  return (
    <Suspense fallback={<SuspenseComponent />}>
      {children}
    </Suspense>
  );
};
