import { AnyAction } from 'redux';

import {
  CLEAR_LOCATION,
  createLocationActions,
  getLocationActions,
  getLocationDictionaryActions,
  locationsActions,
  RESET_LOCATIONS,
  setLocationStatusActions,
} from './actionTypes';
import { ILocationReducer, ILocationsReducer } from './types';

const initialLocationsState: ILocationsReducer = {
  data: {
    items: [],
    total: 0,
    offset: 0,
  },
  isLoading: false,
  isLoaded: false,
  error: null,
};

export function locationsReducer(state = initialLocationsState, action: AnyAction): ILocationsReducer {
  const { payload, type } = action;
  switch (type) {
    case locationsActions.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case locationsActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
        isLoaded: true,
      };
    case locationsActions.FAILURE:
    case createLocationActions.FAILURE:
    case setLocationStatusActions.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isLoaded: true,
      };
    case RESET_LOCATIONS: {
      return {
        ...state,
        ...initialLocationsState,
      };
    }
    default:
      return {
        ...state,
      };
  }
}

const initialLocationState: ILocationReducer = {
  data: undefined,
  status: 'initial',
  error: null,
};

export function locationReducer(state = initialLocationState, action: AnyAction): ILocationReducer {
  const { payload, type } = action;
  switch (type) {
    case getLocationActions.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getLocationActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getLocationActions.FAILURE:
      return {
        ...state,
        error: payload,
        status: 'error',
      };
    case CLEAR_LOCATION:
      return initialLocationState;
    default:
      return {
        ...state,
      };
  }
}

export function locationDictionaryReducer(
  state = initialLocationsState,
  action: AnyAction): ILocationsReducer {
  const { payload, type } = action;
  switch (type) {
    case getLocationDictionaryActions.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case getLocationDictionaryActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
        isLoaded: true,
      };
    case getLocationDictionaryActions.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isLoaded: true,
      };
    case RESET_LOCATIONS: {
      return {
        ...state,
        ...initialLocationsState,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
