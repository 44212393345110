import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { initSplitItApplicationActions } from 'store/spliIt';
import get from 'lodash/get';
import { handleUnauthorizedUser } from 'store/auth';
import {
  SPLITIT_APPLICATION_STAGES,
} from 'applicationConstants';
import { matchPath } from 'react-router';
import { applicationFlowMode } from 'utils/applicationModeUtils';
import {
  ERROR_PAGE,
  SPLIT_IT_CARD_SETUP,
  SPLIT_IT_SUCCESS,
  SPLIT_IT_FAILURE,
  SPLIT_IT_ERROR,
  SUBMISSION_FLOW, NOT_FOUND, DISABLED_LENDER_ERROR,
} from '../../routes';

import { UNEXPECTED_ERROR } from '../app';
import { ICustomHistory } from '../../hooks/useCustomHistory';

export const NavigationMiddleware = (store: MiddlewareAPI<any>) => (
  next: Dispatch,
) => (action: AnyAction) => {
  next(action);
  const { history, payload, raw } = action;
  const { dispatch, getState } = store;
  switch (action.type) {
    case initSplitItApplicationActions.SUCCESS: {
      const { data: { application: { stage } } } = payload;
      if (stage === SPLITIT_APPLICATION_STAGES.created) {
        history.push(SPLIT_IT_CARD_SETUP.url());
      }
      if (stage === SPLITIT_APPLICATION_STAGES.funded) {
        history.push(SPLIT_IT_SUCCESS.url());
      }
      if (stage === SPLITIT_APPLICATION_STAGES.closed
      || stage === SPLITIT_APPLICATION_STAGES.expired
      ) {
        history.push(SPLIT_IT_FAILURE.url());
      }
      if (stage === SPLITIT_APPLICATION_STAGES.config_error
      || stage === SPLITIT_APPLICATION_STAGES.error
      ) {
        history.push(SPLIT_IT_ERROR.url());
      }
      break;
    }
    case UNEXPECTED_ERROR: {
      const params: any = {};

      if (typeof payload === 'string') {
        params.error = payload;
      }
      const errorStatus = get(raw, 'raw.status');
      if (typeof payload === 'object' && errorStatus === 401 && applicationFlowMode === 'general') {
        dispatch(handleUnauthorizedUser(payload));
        return;
      }
      if (typeof payload !== 'string') {
        params.errorObject = JSON.stringify(payload);
      }
      if (payload.slug === 'lender-disabled-error' && history) {
        const { application: { data: { application } } } = getState();
        history.push(DISABLED_LENDER_ERROR.url({}, { lender: application.lender }));
        return;
      }
      if (history) {
        const matchResult = matchPath<{submissionId?: string}>((history as ICustomHistory).location.pathname, {
          path: SUBMISSION_FLOW.pattern,
        });
        if (errorStatus === 404) {
          history.push(NOT_FOUND.url({}, {
            ...params,
            prevPath: (history as ICustomHistory).location.pathname,
            reason: 'Request failed. Check sentry for details',
          }));
          return;
        }
        history.push(ERROR_PAGE.url({}, {
          ...params,
          submissionId: matchResult?.params.submissionId,
          prevPath: (history as ICustomHistory).location.pathname,
        }));
      }
      break;
    }
    default:
  }
};
