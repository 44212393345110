/* eslint-disable max-len */
import { LENDERS } from '../../applicationConstants';

export default {
  general: {
  },
  [LENDERS.greensky]: {
    contact: {
      merchantPhone: '833.204.2794',
    },
  },
  [LENDERS.allegro]: {
    contact: {
      merchantPhone: '800-644-8494',
      merchantAdditionalInfo: 'x 5',
    },
  },
  [LENDERS.greatamerica]: {
  },
  [LENDERS.uga2]: {
    contact: {
      merchantPhone: '888-842-2461',
    },
  },
  [LENDERS.uga_prime]: {
    contact: {
      merchantPhone: '888-842-2461',
    },
  },
  [LENDERS.varidi]: {
    contact: {
      merchantPhone: '(425)-939-4953',
      merchantAdditionalInfo: 'OR (888)-992-8889',
    },
  },
  [LENDERS.cleargage]: {
    contact: {
      merchantPhone: '1-800-531-8259',
    },
  },
  [LENDERS.alphaeon2]: {
  },
};
