import {
  getLenderAccountRequest,
} from 'store/apiRequests';
import {
  getLenderAccountsAction, RESET_LENDER_ACCOUNTS,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { ILenderAccount } from '../../types/application';

export const resetLenderAccounts = () => {
  return {
    type: RESET_LENDER_ACCOUNTS,
  };
};

export const getLenderAccountsThunk = defThunkRequest<{
  lenderApplicationIds: Array<string>
}, {[key: string]: ILenderAccount}>({
  actionTypes: getLenderAccountsAction,
  thunkSteps: [
    async ({ data: { lenderApplicationIds } }) => {
      const res = await Promise
        .all(lenderApplicationIds.map((x) => getLenderAccountRequest(x)));
      return {
        data: res.reduce((acc, value, currentIndex) => {
          if (!value) return acc;
          return {
            ...acc,
            [lenderApplicationIds[currentIndex]]: value.data,
          };
        }, {}),
        raw: {},
      };
    },
  ],
});
