/* eslint-disable no-unused-vars */
import { AnyAction } from 'redux';
import { ICustomHistory } from '../hooks/useCustomHistory';

const loadingSubTypes = ['request'];
const loadedSubTypes = ['success', 'failure'];

export const isLoadingActionType = (actionType: string) => {
  const actionParts = actionType.split('/');
  return actionParts.length === 2 && loadingSubTypes.includes(actionParts[1].toLowerCase());
};

export const isLoadedActionType = (actionType: string) => {
  const actionParts = actionType.split('/');
  return actionParts.length === 2 && loadedSubTypes.includes(actionParts[1].toLowerCase());
};

export interface IActionTypes {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
  request: (payload?: any, history?: ICustomHistory) => AnyAction;
  success: (payload?: any, history?: ICustomHistory) => AnyAction;
  failure: (payload?: any, history?: ICustomHistory) => AnyAction;
}

export const defActionTypes = (base: string): IActionTypes => {
  const types = {
    REQUEST: `${base}/REQUEST`.toUpperCase(),
    SUCCESS: `${base}/SUCCESS`.toUpperCase(),
    FAILURE: `${base}/FAILURE`.toUpperCase(),
  };
  return {
    ...types,
    request: (payload: any, history?: any) => ({ type: types.REQUEST, payload, history }),
    success: (payload: any, history?: any) => ({ type: types.SUCCESS, payload, history }),
    failure: (payload: any, history?: any) => ({ type: types.FAILURE, payload, history }),
  };
};
