import { AnyAction } from 'redux';

import {
  getLenderAccountsAction, RESET_LENDER_ACCOUNTS,
} from './actionTypes';
import { ILenderAccountsReducer } from './types';
import { RESET_APP } from '../app';

const initialState: ILenderAccountsReducer = {
  data: undefined,
  status: 'initial',
};

export function lenderAccountsReducer(
  state = initialState,
  action: AnyAction,
): ILenderAccountsReducer {
  const { payload, type } = action;

  switch (type) {
    case getLenderAccountsAction.REQUEST:
      return {
        ...state,
        status: 'loading',
      };
    case getLenderAccountsAction.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getLenderAccountsAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    case RESET_APP:
    case RESET_LENDER_ACCOUNTS:
      return initialState;
    default:
      return state;
  }
}
