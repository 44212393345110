import { AnyAction } from 'redux';

import { getSubmissionAction } from 'store/submission';
import {
  getInviteDetailsAction,
} from './actionTypes';
import { IBaseReducer } from '../../types/otherData';
import { IInvite } from '../../types/invite';

interface InviteReducer extends Omit<IBaseReducer, 'isLoaded' | 'isLoading'>{
  data?: IInvite;
}

const initialState: InviteReducer = {
  data: undefined,
  status: 'initial',
  error: null,
};

export function inviteReducer(state = initialState, action: AnyAction): InviteReducer {
  const { payload, type } = action;
  switch (type) {
    case getInviteDetailsAction.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getInviteDetailsAction.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getInviteDetailsAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    case getSubmissionAction.SUCCESS: {
      if (!state.data) return state;

      return {
        ...state,
        data: {
          ...state.data,
          submission: payload.data.id,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
}
