import { useEffect, useState } from 'react';

export const useTimer = ({
  initialValue = 0,
  timerValue = 60,
  onTimeout = () => {},
} = {}) => {
  const [currentTick, setTickValue] = useState(initialValue);
  useEffect(() => {
    let timerToken: any;
    if (currentTick > 0) {
      timerToken = setTimeout(() => {
        setTickValue((value) => value - 1);
      }, 1000);
    }

    if (currentTick === 0) {
      onTimeout();
    }

    return () => {
      if (timerToken) clearTimeout(timerToken);
    };
  }, [currentTick]);

  return {
    timerValue: currentTick,
    startTimer: (newValue?: number) => {
      setTickValue(newValue || timerValue);
    },
  };
};
