import React, { lazy } from 'react';
import { applicationFlowMode } from 'utils/applicationModeUtils';

import { GuestContent } from './GuestContent';
import { SuspenseContext } from '../../contexts/SuspenseContext';

const AuthorizedUserContent = lazy(() => import('./AuthorizedUserContent'));

export const UserDropdown = (
  { logoutOnly }
    : {logoutOnly?: boolean;},
) => {
  if (applicationFlowMode !== 'general') {
    return (
      <GuestContent />
    );
  }

  return (
    <SuspenseContext>
      <AuthorizedUserContent
        logoutOnly={logoutOnly}
      />
    </SuspenseContext>
  );
};
