import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  getHfdReportsDownload,
  getManagementTransactionReportsDownload,
  getTransactionReportsDownload,
  getTransactionReportsRequest,
  ReportsParams,
} from '../apiRequests/transactions';
import {
  getHfdReportsAction,
  getManagementTransactionReportsCvsAction,
  getTransactionReportsAction,
  getTransactionReportsCvsAction,
} from './actionTypes';

export const getTransactionReportsThunk = defThunkRequest<ReportsParams | undefined>({
  actionTypes: getTransactionReportsAction,
  thunkSteps: [
    ({ data }) => getTransactionReportsRequest(data || {}),
  ],
});

export const getTransactionReportsCvsThunk = defThunkRequest<ReportsParams | undefined>({
  actionTypes: getTransactionReportsCvsAction,
  thunkSteps: [
    ({ data }) => getTransactionReportsDownload({
      ...(data || {}),
      fileFormat: 'csv',
      count: 0,
    }),
  ],
});

export const getHfdCvsThunk = defThunkRequest<ReportsParams | undefined>({
  actionTypes: getHfdReportsAction,
  thunkSteps: [
    ({ data }) => getHfdReportsDownload({
      ...(data || {}),
      fileFormat: 'csv',
      count: 0,
    }),
  ],
});

export const getManagementTransactionReportsCvsThunk = defThunkRequest<ReportsParams | undefined>({
  actionTypes: getManagementTransactionReportsCvsAction,
  thunkSteps: [
    ({ data }) => getManagementTransactionReportsDownload({
      ...(data || {}),
      fileFormat: 'csv',
      count: 0,
    }),
  ],
});
