import { AnyAction } from 'redux';
import {
  SET_LOADER, RESET_APP, RESET_LOADING_COUNTER,
} from './actionTypes';
import { isLoadedActionType, isLoadingActionType } from '../../utils/defActionTypes';
import { IAppReducer } from './types';

const initialState: IAppReducer = {
  loader: false,
  requests: 0,
};

const increaseLoadingStatus = (state: IAppReducer) => {
  return {
    ...state,
    loader: true,
    requests: state.requests + 1,
  };
};

const decreaseLoadingStatus = (state: IAppReducer) => {
  const newRequestsCount = state.requests - 1;
  return {
    ...state,
    loader: newRequestsCount > 0,
    requests: newRequestsCount > 0 ? newRequestsCount : 0,
  };
};

export function appReducer(
  state = initialState,
  action: AnyAction,
): IAppReducer {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADER: {
      if (payload.loader) {
        return increaseLoadingStatus(state);
      }
      return decreaseLoadingStatus(state);
    }
    case RESET_APP:
      return {
        ...initialState,
        loader: state.loader,
        requests: state.requests,
      };
    case RESET_LOADING_COUNTER:
      return initialState;
    default:
      if (isLoadingActionType(type)) return increaseLoadingStatus(state);
      if (isLoadedActionType(type)) return decreaseLoadingStatus(state);
      return {
        ...state,
      };
  }
}
