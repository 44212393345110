let apiMode = process.env.REACT_APP_API_FAKE_MODE === 'true';

export const setApiMode = (mode: string) => {
  if (mode === 'production') {
    apiMode = false;
  }
};

export const getApiMode = () => {
  if (apiMode) {
    return true;
  }
  return undefined;
};
