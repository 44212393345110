import { useCallback, useMemo, useState } from 'react';

export type ModalControlProps = [open: boolean, control: {open: () => void, close: () => void }];

export const useModalControl = (): ModalControlProps => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  return useMemo(() => {
    return [open, { open: handleOpen, close: handleClose }];
  }, [open, handleOpen, handleClose]);
};
