import { defActionTypes } from '../../utils/defActionTypes';

export const getUsersAction = defActionTypes('get_users_list');
export const addUserAction = defActionTypes('add_user');
export const editUserAction = defActionTypes('edit_user');
export const setUserStatusAction = defActionTypes('set_user_status');

export const getUserByEmailAction = defActionTypes('get_user_by_email');
export const getUserByIdAction = defActionTypes('get_user_by_id');
export const changeUserEmailAction = defActionTypes('change_user_email');
