import { TRANSACTION_STAGE, TRANSACTION_STATUSES } from 'applicationConstants';
import { ITransaction } from '../types/transaction';

type Params = {
  status: ITransaction['status'];
  stage: ITransaction['stage'];
};

export const isTransactionFailed = ({ status, stage }: Params) => {
  return status === TRANSACTION_STATUSES.error
        || status === TRANSACTION_STATUSES.failed
        || status === TRANSACTION_STATUSES.config_error
        || stage === TRANSACTION_STAGE.declined
        || stage === TRANSACTION_STAGE.failed;
};

export const isTransactionCompleted = ({ status, stage }: Params) => {
  return (status === TRANSACTION_STATUSES.ok
        && stage === TRANSACTION_STAGE.settled)
        || (stage === TRANSACTION_STAGE.signed && status !== TRANSACTION_STATUSES.failed);
};
