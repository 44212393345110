import {
  getRegionsListApiRequest,
  createRegionApiRequest,
  editRegionApiRequest,
  deleteRegionApiRequest,
  getRegionApiRequest,
} from '../apiRequests';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { IPaginationData } from '../../types/otherData';
import {
  createRegionActions,
  getRegionActions,
  getRegionsActions,
  RESET_REGIONS,
} from './actionTypes';

export const resetRegions = () => ({
  type: RESET_REGIONS,
});

export const getRegionsListThunk = defThunkRequest<IPaginationData>({
  actionTypes: getRegionsActions,
  thunkSteps: [
    ({ data }) => getRegionsListApiRequest(data),
  ],
});

export const getRegionThunk = defThunkRequest<{ id: string }>({
  actionTypes: getRegionActions,
  thunkSteps: [
    ({ data: { id } }: any) => getRegionApiRequest(id),
  ],
});

export const createRegionThunk = defThunkRequest<{ name: string; id?: string}>({
  actionTypes: createRegionActions,
  thunkSteps: [
    ({ data }: any) => {
      if (data.id) {
        return editRegionApiRequest({ name: data.name }, data.id);
      }
      return createRegionApiRequest(data);
    },
  ],
});

export const deleteRegionThunk = defThunkRequest<{ id: string}>({
  actionTypes: createRegionActions,
  thunkSteps: [
    ({ data }: any) => {
      return deleteRegionApiRequest(data.id);
    },
  ],
});
