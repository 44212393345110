import {
  locationsActions,
  createLocationActions,
  setLocationStatusActions,
  getLocationActions,
  CLEAR_LOCATION,
  getLocationDictionaryActions,
  RESET_LOCATIONS,
} from './actionTypes';
import {
  createLocationApiRequest,
  editLocationApiRequest,
  getInviteLocationRequest,
  getLocationApiRequest,
  getLocationsListApiRequest,
  setLocationStatusApiRequest,
} from '../apiRequests';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { IPaginationData } from '../../types/otherData';
import { getInviteMode } from '../../utils/apiInviteKey';
import { MAX_DICTIONARY_SIZE } from '../../applicationConstants';

export const resetLocations = () => ({
  type: RESET_LOCATIONS,
});

export const getLocationListThunk = defThunkRequest<IPaginationData>({
  actionTypes: locationsActions,
  thunkSteps: [
    ({ data }) => getLocationsListApiRequest(data),
  ],
});

export const getLocationDictionaryThunk = defThunkRequest({
  actionTypes: getLocationDictionaryActions,
  thunkSteps: [
    () => getLocationsListApiRequest({ count: MAX_DICTIONARY_SIZE }),
  ],
});

export const getLocationThunk = defThunkRequest<{locationId: string}>({
  actionTypes: getLocationActions,
  thunkSteps: [
    ({ data: { locationId } }) => {
      const invite = getInviteMode();
      if (invite) return getInviteLocationRequest(invite);
      return getLocationApiRequest(locationId);
    },
  ],
});

export const createLocationThunk = defThunkRequest<{
  id?: string;
  name: string;
  region?: string | null;
  marketingName?: string;
}>({
  actionTypes: createLocationActions,
  thunkSteps: [
    ({ data }) => {
      if (data.id) {
        return editLocationApiRequest({ ...data, id: data.id! });
      }
      return createLocationApiRequest(data);
    },
  ],
});

export const setLocationStatusThunk = defThunkRequest({
  actionTypes: setLocationStatusActions,
  thunkSteps: [
    ({ data }: any) => setLocationStatusApiRequest(data),
  ],
});

export const clearLocation = () => ({
  type: CLEAR_LOCATION,
});
