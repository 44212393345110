import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { FallbackRender } from '@sentry/react/types/errorboundary';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryComponent } from '../../components/ErrorComponents';

const InnerError = ({ errorData }: {
  errorData: {
    error: Error;
    componentStack: string | null;
    eventId: string | null;
    resetError(): void;
  }
}) => {
  const location = useLocation();
  const errorLocation = useRef(location.pathname);
  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      errorData.resetError();
    }
  }, [location.pathname]);
  return <ErrorBoundaryComponent {...errorData} />;
};

const ErrorFallback: FallbackRender = (errorData) => <InnerError errorData={errorData} />;

export const SentryErrorBoundary = ({ children }: {children: React.ReactNode}) => {
  return (
    <Sentry.ErrorBoundary
      fallback={ErrorFallback}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
