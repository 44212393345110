import React from 'react';
import classnames from 'classnames/bind';
import CredCompareSVGIcon from 'components/CredCompareSVGIcons';

import styles from './BackButton.module.scss';

const classnamesModules = classnames.bind(styles);

interface IProps {
  goBack: () => void;
  fixed?: boolean;
  children: any;
}

function BackButton({ goBack, fixed, children }: IProps) {
  const buttonClass = classnamesModules({
    BackButton: true,
    BackButtonFixed: fixed,
  });

  return (
    <button
      type="button"
      className={buttonClass}
      onClick={goBack}
      aria-label="Go back"
    >
      <CredCompareSVGIcon name="arrowLeft" />
      {children}
    </button>
  );
}

BackButton.defaultProps = {
  fixed: false,
};

export default BackButton;
