import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';
import { getInviteMode } from '../../utils/apiInviteKey';
import { LENDERS, LENDERS_TYPE } from '../../applicationConstants';

export interface IValidateCard {
    account_card_number: string;
    account_card_ex: string;
    account_card_cvv: string;
    account_card_zip: string;
    account_aba: string;
}

export const validateCardRequest = (
  id: string,
  body: IValidateCard,
) => apiRequest(`lender_application/${id}/aff/card_validation${toParams({ invite: getInviteMode() })}`, {
  method: 'POST',
  body,
});

export const getPowerPaySignature = (
  id: string,
) => apiRequest(`lender_application/${id}/powerpay/signature${toParams({ invite: getInviteMode() })}`, {
  method: 'POST',
});

export const setHfdOfferRequest = (
  id: string,
  body: { offerId: string },
) => apiRequest(`lender_application/${id}/hfd/set_offer${toParams({ invite: getInviteMode() })}`, {
  method: 'POST',
  body,
});

export const setHfdFirstPaymentDateRequest = (
  id: string,
  body: { firstPaymentDate: string },
) => apiRequest(`transaction/${id}/hfd/update_first_payment_date${toParams({ invite: getInviteMode() })}`, {
  method: 'POST',
  body,
});

export const getLenderRedirectRequest = (
  lenderApplicationId: string,
  lender: LENDERS_TYPE,
) => apiRequest(`lender_application/${lenderApplicationId}/${
  lender === LENDERS.uga2
  || lender === LENDERS.uga_prime
    ? 'uga' : lender
}/redirects`, {
  method: 'GET',
});

export const getAllyDisclosuresRequest = (
  locationId: string,
) => apiRequest(`ally/${locationId}/preapprove_disclosures`, {
  method: 'GET',
});

export const getAllySsnRequest = (
  locationId: string,
) => apiRequest(`ally/${locationId}/ssn`, {
  method: 'GET',
});

export const getAllyApproveDisclosureRequest = (
  applicationId: string,
) => apiRequest(`ally/lender_application/${applicationId}/approve_disclosures`, {
  method: 'GET',
});

export const setHfdServicingOfferRequest = (
  data: { applicationId: string; dp: string; term: number },
) => apiRequest(`lender_application/${data.applicationId}/hfd_servicing/define_offer`, {
  method: 'post',
  body: {
    paymentsNumber: data.term,
    downpayment: data.dp,
  },
});

export const triggerAlphaeonVerificationRequest = (
  id: string,
) => apiRequest(`alphaeon2/${id}/trigger_verification`, {
  method: 'POST',
});
