import { defActionTypes } from '../../utils/defActionTypes';

export const locationsActions = defActionTypes('get_locations');
export const setLocationStatusActions = defActionTypes('set_location_status');
export const createLocationActions = defActionTypes('create_location');

export const getLocationActions = defActionTypes('get_location');
export const getLocationDictionaryActions = defActionTypes('get_location_dictionary');

export const CLEAR_LOCATION = 'CLEAR_LOCATION';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';
