import { AnyAction } from 'redux';

import {
  CLEAR_CONFIGURATIONS,
  configurationsActions,
  getInviteConfigurationsActions,
  updateConfigurationsActions,
} from './actionTypes';
import { IConfigurationsReducer } from './types';

const initialLocationsState: IConfigurationsReducer = {
  data: undefined,
  status: 'initial',
  error: null,
};

export function configurationsReducer(
  state = initialLocationsState,
  action: AnyAction,
): IConfigurationsReducer {
  const { payload, type } = action;
  switch (type) {
    case configurationsActions.REQUEST:
    case updateConfigurationsActions.REQUEST:
    case getInviteConfigurationsActions.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case configurationsActions.SUCCESS:
    case updateConfigurationsActions.SUCCESS:
    case getInviteConfigurationsActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case configurationsActions.FAILURE:
    case updateConfigurationsActions.FAILURE:
    case getInviteConfigurationsActions.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    case CLEAR_CONFIGURATIONS:
      return initialLocationsState;
    default:
      return {
        ...state,
      };
  }
}
