import isEmpty from 'lodash/isEmpty';

const FETCH_TIMEOUT = 45000;
export const endpoint = process.env.REACT_APP_API_ENDPOINT!;

let authToken: string;
export const setAuthToken = (token: string) => {
  authToken = `Bearer ${token}`;
};

export const setAlterToken = (token: string) => {
  authToken = `Basic ${token}`;
};

export const getToken = () => {
  return authToken;
};

async function parseJSON(response: any) {
  const res = await response.text();

  try {
    return {
      raw: response,
      data: JSON.parse(res),
    };
  } catch {
    return { raw: response, notJson: true, data: res };
  }
}

async function parseError(response: any) {
  const res = await response.text();
  try {
    return JSON.parse(res);
  } catch {
    return res;
  }
}

function checkStatus(response: any) {
  if (response.ok) {
    return response;
  }
  return parseError(response).then((payload: any) => {
    // eslint-disable-next-line no-throw-literal
    throw { raw: response, data: payload };
  });
}

function timeoutPromise(timeout: number, err: any, promise: any) {
  let timeoutId: any = null;
  const promiseMethodWrapper = (method: Function) => (...values: any) => {
    clearTimeout(timeoutId);
    return method(...values);
  };
  return new Promise((resolve, reject) => {
    promise.then(promiseMethodWrapper(resolve), promiseMethodWrapper(reject));
    timeoutId = setTimeout(() => {
      reject(err);
    }, timeout);
  });
}

export function apiRequest(path: string, customOptions: any = {}) {
  const { endpoint: customEndpoint = endpoint } = customOptions;
  const fetchUrl = `${customEndpoint}/${path}`;
  const headers = customOptions.headers || new Headers();
  headers.append('accept', 'application/json, text/plain, */*');
  if (!headers.get('authorization') && authToken && !customOptions.surpassAuth) {
    headers.set('authorization', authToken);
  }
  let { body } = customOptions;
  if (!isEmpty(body)) {
    if (!headers.get('content-Type')) {
      headers.append('content-Type', 'application/json');
    }
    if (typeof body === 'object') {
      body = JSON.stringify(body);
    }
  }
  return timeoutPromise(
    FETCH_TIMEOUT,
    new Error('Server timeout'),
    fetch(fetchUrl, {
      ...customOptions,
      headers,
      body,
    }),
  )
    .then(checkStatus)
    .then(parseJSON);
}
