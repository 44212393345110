import { AnyAction } from 'redux';

import {
  getLenderOfferAction,
  getLenderOffersAction,
  RESET_OFFERS,
} from './actionTypes';
import { IOffer } from '../../types/application';
import { IOffersReducer } from './types';
import { RESET_APP } from '../app';

const initialState: IOffersReducer = {
  offers: [],
  offer: undefined,
  status: 'initial',
};

const sortOffers = (offers: Array<IOffer>) => {
  const sorted = offers.reduce((acc: { [key: number]: IOffer }, value: IOffer) => {
    if (!acc[value.financingTerm]) {
      acc[value.financingTerm] = value;
      return acc;
    }
    if (acc[value.financingTerm]
        && acc[value.financingTerm].recurringAmount === value.recurringAmount
        && value.planName === 'SAC'
    ) {
      acc[value.financingTerm] = value;
      return acc;
    }
    if (acc[value.financingTerm] && acc[value.financingTerm].recurringAmount > value.recurringAmount) {
      acc[value.financingTerm] = value;
    }
    return acc;
  }, {});
  return Object.values(sorted);
};

export function offersReducer(state = initialState, action: AnyAction): IOffersReducer {
  const { payload, type } = action;
  switch (type) {
    case getLenderOffersAction.SUCCESS:
      return {
        ...state,
        offers: sortOffers(payload.data.items),
        status: 'loaded',
      };
    case getLenderOfferAction.SUCCESS:
      return {
        ...state,
        offer: payload.data,
      };
    case RESET_OFFERS:
    case RESET_APP:
      return initialState;
    default:
      return {
        ...state,
      };
  }
}
