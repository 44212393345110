import { USER_STATUSES } from 'applicationConstants';
import { IPaginationData } from '../../types/otherData';
import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';

export const getRegionsListApiRequest = (
  data?: IPaginationData,
) => apiRequest(
  `region${toParams({
    start: data?.start,
    count: data?.count,
    name: data?.search,
    status: ['active'],
  })}`,
  {
    method: 'GET',
  },
);

export const createRegionApiRequest = (
  body: {name: string},
) => apiRequest(
  'region',
  {
    method: 'POST',
    body,
  },
);

export const deleteRegionApiRequest = (
  id: string,
) => apiRequest(
  `region/${id}/status`,
  {
    method: 'POST',
    body: {
      status: USER_STATUSES.closed,
    },
  },
);

export const editRegionApiRequest = (
  body: {name: string},
  id: string,
) => apiRequest(
  `region/${id}`,
  {
    method: 'POST',
    body,
  },
);

export const getRegionApiRequest = (
  id: string,
) => apiRequest(
  `region/${id}`,
  {
    method: 'GET',
  },
);
