import React from 'react';
import cx from 'classnames';
import { createStyles, Typography, TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
    customColor?: string;
    fontSize?: string | number;
    lineHeight?: string | number;
    fontWeight?: any;
    letterSpacing?: number;
    className?: string;
    component?: React.ReactElement | string;
}

type IFullProps = IProps & TypographyProps;

const useStyles = makeStyles(() => createStyles({
  ccTypographyContent: {
    color: ({ customColor }: IProps) => (customColor || ''),
    letterSpacing: ({ letterSpacing }: IProps) => letterSpacing,
    lineHeight: ({ lineHeight }: IProps) => lineHeight,
    fontSize: ({ fontSize }: IProps) => fontSize,
    fontWeight: ({ fontWeight }: IProps) => fontWeight,
  },
}));

export const CcTypography = ({
  customColor, fontSize, lineHeight, letterSpacing, className, children, fontWeight, ...rest
}: IFullProps) => {
  const styles = useStyles({
    fontSize, customColor, lineHeight, letterSpacing, fontWeight,
  });
  return (
    <Typography
      className={cx(styles.ccTypographyContent, className)}
      {...rest}
    >
      {children}
    </Typography>
  );
};
