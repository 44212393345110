import {
  getLenderApplicationLogsRequest,
} from 'store/apiRequests';
import {
  getApplicationLogs,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';

export const getApplicationLogsThunk = defThunkRequest<{ applicationId: string; reason: string }>({
  actionTypes: getApplicationLogs,
  thunkSteps: [
    ({ data }) => getLenderApplicationLogsRequest(data),
  ],
});
