interface ITawk {
    onLoad: () => void;
    setAttributes(info: any, cb: (err: any) => void): void;
    maximize: () => void;
}

class TawkProxy implements ITawk {
  private api: ITawk;

  private isLoaded: boolean = false;

  private actionQueque: Array<Function> = [];

  constructor() {
    // eslint-disable-next-line camelcase
    // @ts-ignore
    this.api = {};
  }

  init() {
    // eslint-disable-next-line camelcase
    // @ts-ignore
    window.Tawk_API = window.Tawk_API || {};
    // eslint-disable-next-line camelcase
    // @ts-ignore
    window.Tawk_LoadStart = new Date();

    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = `https://embed.tawk.to/${process.env.REACT_APP_TAWK_CHAT_ID}`;
    s1.setAttribute('crossorigin', '*');
    s0.parentNode?.insertBefore(s1, s0);

    s1.addEventListener('load', () => {
      // eslint-disable-next-line camelcase
      // @ts-ignore
      this.api = window.Tawk_API;
      this.api.onLoad = this.onLoad;
    });
  }

  onLoad = () => {
    this.isLoaded = true;

    while (this.actionQueque.length > 0) {
      const action = this.actionQueque.pop();
      if (action) action();
    }
  };

  maximize(): void {
    const action = () => {
      this.api.maximize();
    };

    if (this.isLoaded) {
      action();
    } else {
      this.actionQueque.push(action);
    }
  }

  setAttributes(info: any, cb: (err: any) => void): void {
    const action = () => {
      this.api.setAttributes(info, cb);
    };

    if (this.isLoaded) {
      action();
    } else {
      this.actionQueque.push(action);
    }
  }
}

const instance = new TawkProxy();

export const TawkProxyInstance = instance;
