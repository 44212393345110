import { toParams } from 'utils/params';
import { apiRequest } from '../../utils/apiRequest';
import { IPaginationData } from '../../types/otherData';
import { getInviteMode } from '../../utils/apiInviteKey';
import { IApplicant } from '../../types/application';

export type InitSplititPayload = {
    location?: string;
    body: {
        applicant: Omit<IApplicant, 'dob' | 'cellPhone' | 'location' | 'network'>,
        application: {
            requestedLoanAmount: number;
            numberOfInstallments: number;
            succeededUrl: string;
            canceledUrl: string;
            failedUrl: string
        }
    }
};

export const initSplititApiRequest = ({ location, body }: InitSplititPayload) => apiRequest(
  `splitit_application${toParams({ invite: getInviteMode(), location })}`, {
    method: 'POST',
    body,
  },
);

export const getSplititApplicationApiRequest = (id: string) => apiRequest(
  `splitit_application/${id}${toParams({ invite: getInviteMode() })}`, {
    method: 'GET',
  },
);

export const refundSplititApplicationApiRequest = (id: string, amount: number) => apiRequest(
  `splitit_application/${id}/refund${toParams({ amount })}`, {
    method: 'POST',
  },
);

export const getSplititApplicationListApiRequest = (
  data?: IPaginationData,
) => apiRequest(
  `splitit_application${toParams({
    name_query: data?.search,
    start: data?.start,
    count: data?.count,
    location: data?.location,
    assignee: data?.assignee,
  })}`,
  {
    method: 'GET',
  },
);
