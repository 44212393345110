import {
  deleteApplicantRequest,
  getApplicantRequest,
  patchApplicantRequest,
} from 'store/apiRequests';
import {
  getApplicantAction, deleteApplicantAction, patchApplicantAction,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { IApplicant } from '../../types/application';

export const getApplicantThunk = defThunkRequest<{ applicantId: string; }, IApplicant>({
  actionTypes: getApplicantAction,
  thunkSteps: [
    ({ data: { applicantId } }) => getApplicantRequest(applicantId),
  ],
});

export const deleteApplicantThunk = defThunkRequest<{ applicantId: string; }, IApplicant>({
  actionTypes: deleteApplicantAction,
  thunkSteps: [
    ({ data: { applicantId } }) => deleteApplicantRequest(applicantId),
  ],
});

export const patchApplicantThunk = defThunkRequest<{
  applicantId: string;
  email?: string;
  phone?: string;
}, IApplicant>({
  actionTypes: patchApplicantAction,
  thunkSteps: [
    ({ data }) => patchApplicantRequest(data),
  ],
});
