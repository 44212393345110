import { AnyAction } from 'redux';

import {
  getApplicationLogs,
} from './actionTypes';
import { IApplicationLogsReducer } from './types';

const initialState: IApplicationLogsReducer = {
  data: undefined,
  status: 'initial',
};

export function applicationLogsReducer(
  state = initialState,
  action: AnyAction,
): IApplicationLogsReducer {
  const { payload, type } = action;

  switch (type) {
    case getApplicationLogs.REQUEST:
      return {
        ...state,
        status: 'loading',
      };
    case getApplicationLogs.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getApplicationLogs.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
