import { Box, createStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { HorizontalSeparator } from 'components/HorizontalSeparator';

const useStyles = makeStyles((theme) => createStyles({
  subContent: {
    marginTop: 16,
    marginBottom: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  buttonText: {
    ...theme.typography.body2,
    textTransform: 'initial',
    color: 'rgba(151, 151, 151, 1)',
  },
}));

export const MobileGoBack = ({ onGoBack }: {onGoBack: () => void}) => {
  const styles = useStyles();

  return (
    <Box className={styles.subContent}>
      <HorizontalSeparator
        textColor="rgba(151, 151, 151, 1)"
      />
      <Button
        aria-label="Go back(mobile)"
        variant="text"
        className={styles.buttonText}
        onClick={onGoBack}
      >
        Go back
      </Button>
    </Box>
  );
};
