import React, { useEffect, useState } from 'react';
import { useTimer } from '../../hooks/useTimer';
import { useModalControl } from '../../hooks/useModalControll';
import { VersionUpdateModal } from './VersionUpdateModal';
import { getApplicationVersion } from '../../store/apiRequests';

let fakeVersion: string | undefined;
const setFakeVersion = (version?: string) => {
  if (!version) {
    throw new Error('Version is not defined');
  }
  fakeVersion = version;
};

// @ts-ignore
window.setFakeVersion = setFakeVersion;

/**
 * `VersionUpdate` is a React component that periodically checks for application updates.
 * It fetches actual version from node.js server and opens a `VersionUpdateModal` if a new version is detected.
 * A fake version can be set for testing purposes using the `setFakeVersion` function.
 *
 * @returns {React.Element} A `VersionUpdateModal` if a new version is available, otherwise `null`.
 */
export const VersionUpdate = () => {
  const [open, openController] = useModalControl();
  const [initialVersion, setInitialVersion] = useState<string | undefined>(undefined);

  const { startTimer, timerValue } = useTimer();

  useEffect(() => {
    if (timerValue !== 0) return;
    getApplicationVersion()
      .then(({ data }) => {
        startTimer();

        if (initialVersion === undefined) {
          setInitialVersion(data.version);
          return;
        }
        if (
          (fakeVersion && fakeVersion !== data.version)
            || data.version !== initialVersion
        ) openController.open();
      }).catch(() => {
        startTimer();
      });
  }, [timerValue]);

  if (open) {
    return <VersionUpdateModal />;
  }

  return null;
};
