import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';
import { ALLOWED_MODE } from '../../applicationConstants';

export const getNetworksListApiRequest = () => apiRequest(
  'network',
  {
    method: 'GET',
  },
);

export const setNetworkApiRequest = (networkId: string) => apiRequest(
  `user/me/network${toParams({ network: networkId })}`,
  {
    method: 'POST',
  },
);

export type CreateNetworkBody = {
  name: string;
  mode: ALLOWED_MODE,
  flags: {
    enableFinalPrice: boolean;
    enableSelectLenderByDefault: boolean;
    guestOnlyFlow: boolean;
    managersOnlyRefunds: boolean;
    preferLowestMonthlyOffer: boolean;
  };
};

export const createNetworkApiRequest = (body: CreateNetworkBody) => apiRequest(
  'network',
  {
    method: 'POST',
    body: {
      ...body,
      configuration: {
        lenderTiers: [],
        enablePublicLink: false,
      },
    },
  },
);
