import { defThunkRequest } from '../../utils/defAsyncAction';
import { reportApplicationNoOffersErrorAction, reportGeneralErrorAction } from './actionTypes';
import { apiRequest } from '../../utils/apiRequest';
import { moneyFormatting } from '../../utils/moneyFormatting';

const getContentFormatting = (content: any, eventType: 'alert' | 'notification') => {
  if (eventType === 'notification') {
    return `____________________\n${JSON
      .stringify(content)
      .replace(
        /,/g,
        '\n\n',
      )
      .replace(
        /{/g,
        '',
      )
      .replace(
        /}/g,
        '',
      )
      .replace(
        /:/g,
        ': ',
      )
      .replace(
        /"/g,
        '',
      )}`;
  }

  return JSON
    .stringify(content);
};

export const reportGeneralError = defThunkRequest<{
  content: any;
  eventType: 'alert' | 'notification';
  traceId?: string}>({
    actionTypes: reportGeneralErrorAction,
    thunkSteps: [({ data: { content, traceId, eventType } }) => apiRequest(
      'notification/event',
      {
        method: 'POST',
        body: JSON.stringify({
          eventType,
          content: getContentFormatting(content, eventType),
          traceId,
          location: '',
        }),
      },
    )],
  });

export const reportApplicationNoOffersError = defThunkRequest({
  actionTypes: reportApplicationNoOffersErrorAction,
  thunkSteps: [({ getState }) => {
    const state = getState();
    const {
      auth: {
        userInfo,
      },
      application: {
        lenderAmounts,
        data: {
          application: {
            id,
            lender,
          },
        },
      },
    } = state;
    return apiRequest(
      'notification/event',
      {
        method: 'POST',
        body: JSON.stringify({
          eventType: 'alert',
          content: JSON.stringify({
            message: 'no offers on offers page',
            applicationId: id,
            lender,
            lenderAmounts: {
              loanAmount: moneyFormatting(lenderAmounts.loanAmount).replace(',', ''),
              creditLimit: moneyFormatting(lenderAmounts.creditLimit).replace(',', ''),
              minAmount: lenderAmounts.minAmount
                  && moneyFormatting(lenderAmounts.minAmount).replace(',', ''),
            },
            userId: userInfo?.id,
          }).replace(
            /,/g,
            '\n',
          ),
          location: '',
        }),
      },
    );
  }],
});
