import {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { boolean } from 'yup';
import { LadyStyle } from '../types';

export const ApplicationNavigationContext = createContext({
  currentStep: undefined as number | undefined,
  ladyStyle: undefined as LadyStyle | undefined,
  goBack: undefined as string | boolean | undefined,
  useMobileGoBack: undefined as boolean | undefined,
  setConfig: (config: {
    currentStep?: number;
    ladyStyle?: LadyStyle
    goBack?: string | boolean;
    useMobileGoBack?: boolean,
  }) => { },
});

export const useApplicationNavigationContextData = () => {
  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);
  const [ladyStyle, setLadyStyle] = useState<LadyStyle>();
  const [goBackValue, setGoBack] = useState<string | boolean | undefined>();
  const [useMobileGoBack, setMobileGoBack] = useState< boolean | undefined>();

  const setConfig = useCallback((config: {
    currentStep?: number;
    ladyStyle?: LadyStyle;
    goBack?: string | boolean;
    useMobileGoBack?: boolean;
  }) => {
    setCurrentStep(config.currentStep);
    setLadyStyle(config.ladyStyle);
    setGoBack(config.goBack);
    setMobileGoBack(config.useMobileGoBack);
  }, []);

  return useMemo(() => {
    return {
      currentStep,
      ladyStyle,
      setConfig,
      goBack: goBackValue,
      useMobileGoBack,
    };
  }, [currentStep, ladyStyle, setConfig, goBackValue, useMobileGoBack]);
};

export const useApplicationNavigationContext = () => {
  return useContext(ApplicationNavigationContext);
};
