import React from 'react';
import { ModalDialog } from 'components/ModalDialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTextMapper } from 'hooks/useTextMapper';
import { Typography } from '@material-ui/core';
import JSONPretty from 'react-json-pretty';

const useStyles = makeStyles(() => createStyles({
  content: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& pre': {
      whiteSpace: 'pre-wrap',
    },
  },
  dialogContent: {
    marginTop: 0,
    alignItems: 'stretch',
    textAlign: 'start',
  },
  subTitle: {
    color: '#090224',
  },
}));

export const MoreInfoModal = ({
  onClose,
  contentItems,
}: any) => {
  const styles = useStyles();
  const getText = useTextMapper();

  return (
    <ModalDialog
      height={500}
      onClose={onClose}
      title={getText('global.errorPage.moreInfo')}
      classes={{
        dialogContent: styles.dialogContent,
      }}
    >
      <section className={styles.content}>
        {Object.keys(contentItems).map((x) => contentItems[x] && (
        <section key={x}>
          <Typography variant="h2" className={styles.subTitle}>
            {x}
          </Typography>
          {
                    x === 'errorObject'
                      ? <JSONPretty id="json-pretty" data={contentItems[x]} />
                      : contentItems[x].toString()
                }
        </section>
        ))}
      </section>
    </ModalDialog>
  );
};
