import { AnyAction } from 'redux';
import { getListOfLocationsLendersAction } from './actionTypes';
import { IAccountLookupLocations } from '../../types/accountLookup';

type LookupState = {
  isLoading: boolean;
  data?: IAccountLookupLocations;
};

const initialState: LookupState = {
  isLoading: false,
  data: undefined,
};

export function accountLookupReducer(
  state = initialState,
  action: AnyAction,
): LookupState {
  const { type, payload } = action;
  switch (type) {
    case getListOfLocationsLendersAction.REQUEST:
      return {
        ...state,
        data: undefined,
        isLoading: true,
      };
    case getListOfLocationsLendersAction.SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
      };
    case getListOfLocationsLendersAction.FAILURE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
