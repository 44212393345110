import { AnyAction } from 'redux';

import flatten from 'lodash/flatten';
import {
  getLenderApplicationsActions,
} from './actionTypes';
import { RESET_APP } from '../app';
import { IBaseReducer } from '../../types/otherData';
import { IApplication } from '../../types/application';
import { LENDER_APPLICATION_UPDATE } from '../application';

interface ILenderApplications extends IBaseReducer {
  data: {
    items: Array<IApplication>;
    serviceLender?: IApplication;
  }
}

const initialState: ILenderApplications = {
  data: { items: [] },
  status: 'initial',
  error: null,
};

export function lenderApplicationsReducer(state = initialState, action: AnyAction): ILenderApplications {
  const { payload, type } = action;
  switch (type) {
    case getLenderApplicationsActions.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getLenderApplicationsActions.SUCCESS:
      return {
        ...state,
        data: {
          items: flatten(payload.data.tiers),
          serviceLender: payload.data.serviceLender,
        },
        status: 'loaded',
      };
    case LENDER_APPLICATION_UPDATE:
      if (state.status !== 'loaded') return state;
      return {
        ...state,
        data: {
          items: state.data.items.map((x) => {
            if (x.id === action.payload.id) {
              return action.payload;
            }
            return x;
          }),
          serviceLender: state.data.serviceLender?.id === action.payload.id
            ? action.payload
            : state.data.serviceLender,
        },
      };
    case getLenderApplicationsActions.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    case RESET_APP:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
}
