import { AnyAction } from 'redux';

import {
  splitItApplicationsActions,
  initSplitItApplicationActions,
  refundSplitItApplicationActions,
} from './actionTypes';

const initialState = {
  data: { application: {} },
  isLoaded: false,
  error: null,
};

const initialApplicationsState = {
  data: { items: [] },
  isLoading: false,
  error: null,
};

export function splititReducer(state = initialState, action: AnyAction) {
  const { type } = action;
  switch (type) {
    case initSplitItApplicationActions.SUCCESS:
      return {
        ...state,
        isLoaded: true,
        data: action.payload.data,
      };
    case initSplitItApplicationActions.FAILURE:
      return {
        ...state,
        isLoaded: true,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

export function splititApplicationsReducer(state = initialApplicationsState, action: AnyAction): any {
  const { payload, type } = action;
  switch (type) {
    case splitItApplicationsActions.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case splitItApplicationsActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
      };
    case refundSplitItApplicationActions.SUCCESS: {
      const { data: { application: { id } } } = payload;
      return {
        ...state,
        isLoaded: true,
        data: {
          ...state.data,
          items: state.data.items.map((x: any) => {
            if (x.application.id === id) {
              return payload.data;
            }
            return x;
          }),
        },
      };
    }
    case splitItApplicationsActions.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
