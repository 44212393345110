import { AnyAction } from 'redux';

import {
  getTransactionReportsAction,
} from './actionTypes';
import { ITransactionReportsReducer } from './types';

const initialTransactionsState: ITransactionReportsReducer = {
  data: undefined,
  status: 'initial',
  error: undefined,
};

export function transactionReportsReducer(
  state = initialTransactionsState,
  action: AnyAction,
): ITransactionReportsReducer {
  const { payload, type } = action;

  switch (type) {
    case getTransactionReportsAction.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getTransactionReportsAction.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getTransactionReportsAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return state;
  }
}
