import { AnyAction } from 'redux';
import { RESET_APP } from 'store/app';
import { v4 as uuid } from 'uuid';

import {
  getSubmissionAction, SUBMISSION_UPDATE,
} from './actionTypes';
import { IShortenLenderApplication, ISubmission } from '../../types/submissions';
import { IBaseReducer } from '../../types/otherData';
import { LENDER_APPLICATION_UPDATE } from '../application';
import { submitKbaChallengeAction } from '../kbaChallenge';

type UpdateType = {
  wsUpdateType?: 'applicationChanged' | 'applicationDeleted';
  updateUUIID?: string;
};

interface IState extends IBaseReducer, UpdateType {
  data: ISubmission;
}

const initialState: IState = {
  data: { } as ISubmission,
  status: 'initial',
  error: null,
};

const getWSUpdateType = (newApp?: IShortenLenderApplication, oldApp?: IShortenLenderApplication): UpdateType => {
  if (oldApp && !newApp) {
    return {
      wsUpdateType: 'applicationDeleted',
      updateUUIID: uuid(),
    };
  }
  if (newApp?.id !== oldApp?.id) {
    return {
      wsUpdateType: 'applicationChanged',
      updateUUIID: uuid(),
    };
  }
  return { };
};

export function submissionReducer(state = initialState, action: AnyAction): IState {
  const { type } = action;
  switch (type) {
    case getSubmissionAction.REQUEST: {
      return {
        ...state,
        status: 'loading',
      };
    }
    case getSubmissionAction.SUCCESS: {
      const { payload: { data } } = action;

      return {
        ...state,
        status: 'loaded',
        data,
      };
    }
    case SUBMISSION_UPDATE: {
      return {
        ...state,
        data: action.payload,
        ...getWSUpdateType(
          action.payload.currentLenderApplication,
          state.data.currentLenderApplication,
        ),
      };
    }
    case LENDER_APPLICATION_UPDATE: {
      if (action.payload.id !== state.data.currentLenderApplication?.id) return state;
      return {
        ...state,
        data: {
          ...state.data,
          currentLenderApplication: action.payload,
        },
      };
    }
    case submitKbaChallengeAction.SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          currentLenderApplication: action.payload.data,
        },
      };
    }
    case getSubmissionAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: action.payload,
      };
    case RESET_APP:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
}
