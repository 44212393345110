import { COLORS } from 'applicationConstants';

const primary = {
  light: COLORS.activeBlue,
  main: COLORS.mainBlue,
  dark: COLORS.darkBlue,
  contrastText: COLORS.white,
};

const secondary = {
  light: '#7841ff',
  main: '#2800f0',
  dark: '#0000bc',
  contrastText: COLORS.white,
};

export {
  primary,
  secondary,
};
