import { AnyAction } from 'redux';
import { getSsoDomainsActions } from './actionTypes';
import { SsoDomainsReducer } from './types';

const initialState: SsoDomainsReducer = {
  data: { items: [], total: 0, offset: 0 },
  status: 'initial',
  error: null,
};

export function ssoDomainReducer(state = initialState, action: AnyAction): SsoDomainsReducer {
  const { payload, type } = action;
  switch (type) {
    case getSsoDomainsActions.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getSsoDomainsActions.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getSsoDomainsActions.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
