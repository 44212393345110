import {
  getQuotesApiRequest,
} from '../apiRequests';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { Quote } from '../../types/quotes';
import { IPaginationResponse } from '../../types/otherData';
import {
  getQuotesActions,
} from './actionTypes';

export const getQuotesThunk = defThunkRequest<{id: string}, IPaginationResponse<Quote>>({
  actionTypes: getQuotesActions,
  thunkSteps: [
    ({ data: { id } }) => getQuotesApiRequest(id),
  ],
});
