import get from 'lodash/get';

import {
  authByInviteRequest,
  getSplititApplicationApiRequest,
  getSplititApplicationListApiRequest,
  initSplititApiRequest,
  InitSplititPayload,
  refundSplititApplicationApiRequest,
} from 'store/apiRequests';
import { SPLITIT_APPLICATION_STAGES } from 'applicationConstants';
import {
  splitItApplicationsActions,
  initSplitItApplicationActions, refundSplitItApplicationActions,
} from './actionTypes';
import { waitWhileCondition } from '../../utils/requestWithTimeout';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { IPaginationData } from '../../types/otherData';
import { getInviteMode } from '../../utils/apiInviteKey';
import { setAlterToken } from '../../utils/apiRequest';

export const initSplititThunk = defThunkRequest<InitSplititPayload>({
  actionTypes: initSplitItApplicationActions,
  thunkSteps: [
    ({ data }) => initSplititApiRequest(data),
    async ({ prevResponse, data }) => {
      if (getInviteMode()) {
        const { body: { applicant: { zipCode } } } = data;
        const { data: { token } } = await authByInviteRequest({ zipCode });
        setAlterToken(token);
      }

      return prevResponse;
    },
  ],
});

export const getSplititApplicationThunk = defThunkRequest<{id: string, waitForFunded?: boolean}>({
  actionTypes: initSplitItApplicationActions,
  thunkSteps: [
    ({ data: { id } }) => getSplititApplicationApiRequest(id),
    ({ prevResponse, data: { id, waitForFunded } }) => {
      const stage = get(prevResponse, 'data.application.stage');
      if (!waitForFunded || stage !== SPLITIT_APPLICATION_STAGES.created) return prevResponse;
      return waitWhileCondition({
        request: () => getSplititApplicationApiRequest(id),
        condition: (data: any) => {
          const localStatus = get(data, 'application.stage');
          return localStatus === SPLITIT_APPLICATION_STAGES.created;
        },
      });
    },
  ],
});

export const getSplititApplicationsThunk = defThunkRequest<IPaginationData>({
  actionTypes: splitItApplicationsActions,
  thunkSteps: [
    ({ data }) => getSplititApplicationListApiRequest(data),
  ],
});

export const refundSplititApplicationsThunk = defThunkRequest<{
  id: string;
  amount: number;
}>({
  actionTypes: refundSplitItApplicationActions,
  thunkSteps: [
    ({ data: { id, amount } }) => refundSplititApplicationApiRequest(id, amount),
  ],
});
