export const initiatePolifills = () => {
  if (![].at) {
    // eslint-disable-next-line no-extend-native,func-names
    Array.prototype.at = function (pos) {
      return this.slice(pos, pos + 1)[0];
    };
  }

  if (!('allSettled' in Promise)) {
    // eslint-disable-next-line func-names
    (Promise as any).allSettled = function <T> (
      promises: Array<Promise<T>>,
    ): Promise<Array<{ status: 'fulfilled' | 'rejected'; value?: T; reason?: any }>> {
      return Promise.all(
        promises.map((promise) => Promise.resolve(promise)
          .then((value) => ({ status: 'fulfilled', value } as const))
          .catch((reason) => ({ status: 'rejected', reason } as const)),
        ),
      );
    };
  }

  if (!('finally' in Promise.prototype)) {
    // eslint-disable-next-line func-names
    (Promise.prototype as any).finally = function <T> (
      this: Promise<T>,
      onFinally?: (() => void) | null | undefined,
    ): Promise<T> {
      const P = this.constructor as PromiseConstructor;
      return this.then(
        (value: T) => P.resolve(onFinally && onFinally()).then(() => value),
        (reason: any) => P.resolve(onFinally && onFinally()).then(() => {
          throw reason;
        }),
      );
    };
  }
};
