import {
  getLenderAccountRequest,
} from 'store/apiRequests';
import {
  resetLenderAccountType,
  getLenderAccountAction, getLenderAccountWithoutUpdateAction,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';

import { waitWhileCondition } from '../../utils/requestWithTimeout';
import { ILenderAccount } from '../../types/application';

export const resetLenderAccount = () => {
  return {
    type: resetLenderAccountType,
  };
};

export const getLenderAccountThunk = defThunkRequest<{ lenderApplicationId: string }, ILenderAccount>({
  actionTypes: getLenderAccountAction,
  thunkSteps: [
    ({ data: { lenderApplicationId } }) => waitWhileCondition({
      request: () => {
        try {
          return getLenderAccountRequest(lenderApplicationId);
        } catch {
          return { data: null };
        }
      },
      condition: (data: any) => {
        return !data;
      },
      failureMessage: `Didn't receive lender account information within timeout for ${lenderApplicationId}`,
      timeout: 60,
    }),
  ],
});

export const getLenderAccountWithoutUpdateThunk = defThunkRequest<
  { lenderApplicationId: string },
  ILenderAccount
>({
  actionTypes: getLenderAccountWithoutUpdateAction,
  thunkSteps: [
    ({ data: { lenderApplicationId } }) => waitWhileCondition({
      request: () => {
        try {
          return getLenderAccountRequest(lenderApplicationId);
        } catch {
          return { data: null };
        }
      },
      condition: (data: any) => {
        return !data;
      },
      failureMessage: `Didn't receive lender account information within timeout for ${lenderApplicationId}`,
      timeout: 60,
    }),
  ],
});
